import { useState, useEffect } from 'react';

let cachedScripts = [];
export default function ScriptLoader(props) {

    const resourceUrl = props.url;
    console.log(resourceUrl);

    const [loadedState, setLoadedState] = useState({ loaded: cachedScripts.includes(resourceUrl), error: false });

    useEffect(() => {
        if (cachedScripts.includes(resourceUrl)) {
            setLoadedState({
              loaded: true,
              error: false
            });
          } else {
            
        const script = document.createElement('script');
        script.type = 'text/javascript';
        script.src = resourceUrl;
  //      script.async = true;
        //        script.defer = true;

        const onScriptLoad = () => {
            setLoadedState({ loaded: true, error: false });
            cachedScripts.push(resourceUrl);
            };

            const onScriptError = () => {
                setLoadedState({ loaded: false, error: true });
                script.remove();
            };

            script.addEventListener('load', onScriptLoad);
            script.addEventListener('error', onScriptError);

            document.head.appendChild(script);


            return () => {
                script.removeEventListener('load', onScriptLoad);
                script.removeEventListener('error', onScriptError);
                setLoadedState({ loaded: false, error: false });

                document.head.removeChild(script);
            }
        }
        }, [resourceUrl]);


    return ( [loadedState.loaded, loadedState.error] );

}