import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';



const useStyles = makeStyles(theme => ({
    root: {
        flexGrow: 1,
    },
    menuButton: {
        marginRight: theme.spacing(2),
    },
    search: {
        backgroundColor: 'white',
    },
}));

export default function RegisterToolbar(props) {
    const classes = useStyles();
    const showme = props.showme;

    const [anchorEl, setAnchorEl] = React.useState(null);


    const openMenu = event => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = (event, dest) => {
        setAnchorEl(null);
        if (dest === "-1") { return (showme('home')); }
        if (dest === "0") { return (showme('sites')); }
        if (dest === "1") { return (showme('signin')); }

    };





    return (
        <div className={classes.root}>
            <AppBar position="static">
                <Toolbar variant="dense">

                        <IconButton onClick={openMenu} edge="start" className={classes.menuButton} color="inherit" aria-label="menu">
                            <MenuIcon />
                        </IconButton>

                       

                        
                    <Typography variant="h6" style={{ flex: 1 }} >

          </Typography>

          
                    

                </Toolbar>
            </AppBar>

            <Menu
                id="top-menu"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleClose}
            >
                <MenuItem value="home" onClick={(evt) => handleClose(evt, '-1')} divider={true}>Home</MenuItem>
                <MenuItem value="sites" onClick={(evt) => handleClose(evt, '0')} divider={true}>Places</MenuItem>
                <MenuItem value="signin" onClick={(evt) => handleClose(evt, '1')} divider={true}>Sign In</MenuItem>

                
            </Menu>

            
        </div>
    );
}