import React, { useEffect } from 'react';


import SpeedDial from '@material-ui/lab/SpeedDial';

import SpeedDialAction from '@material-ui/lab/SpeedDialAction';

import CaravanIcon from '../icons/caravan';
import TentIcon from '../icons/tent';
import VisibilityIcon from '@mui/icons-material/Visibility';
import BeachAccessIcon from '@mui/icons-material/BeachAccess';


import { makeStyles } from '@material-ui/core/styles';

import { green, blue, grey , orange , red} from '@material-ui/core/colors'

import SiteCache from './siteCache';

const useStyles = makeStyles((theme) => ({

    speedd: {
        position: 'absolute',
        left: '10px',
        top: '50px',
        zIndex: 2,
    }
}));

const siteCache = new SiteCache();



export default function SiteSetSelection(props) {

    const classes = useStyles();
    const api = props.api;
    const tellme = props.tellme;

    const [open, setOpen] = React.useState(false);


    useEffect(() => {
        siteCache.setApi(api);
    }, [api]);


    const [state, setState] = React.useState({
        my_visits: false,
        car: false,
        cam: false,
        car_cl: false,
        cam_cs: false,
        other: false,
    });

    const actions = [
        { icon: state.car?<CaravanIcon style={{ color: grey[500] }} />:<CaravanIcon style={{ color: orange[800] }} />, name: 'car', title: 'CAMC' },
        { icon: state.cam?<TentIcon style={{ color: grey[500] }}/>:<TentIcon style={{ color: blue[800] }}/>, name: 'cam', title: 'CCC' },
        { icon: state.car_cl?<CaravanIcon style={{ color: grey[500] }} />:<CaravanIcon style={{ color: green[900] }} />, name: 'car_cl', title: 'CLs' },
//        { icon: state.cam_cs?<TentIcon style={{ color: grey[500] }}/>:<TentIcon style={{ color: green[600] }}/>, name: 'cam_cs', title: 'CSs' },
        { icon: state.other?<BeachAccessIcon style={{ color: grey[500] }}/>:<BeachAccessIcon style={{ color: red[800] }}/>, name: 'other', title: 'Other' },
      ];

    const handleChange = (event, name) => {
        console.log(name);
        var a = { ...state, [name]: !state[name] };
        setState(a);
        alldone2(a);
        setOpen(false);
    };



    async function alldone2(st) {
        var sites = [];

        for (var val in st) {

            if (st[val] === true) {
                var res = await siteCache.getSet(val);
                res.forEach((val) => {
                    sites.push(val);
                })
            }
        }

        tellme(sites);
    }





  

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen = () => {
    setOpen(true);
  };

    return (

        <SpeedDial
          ariaLabel="Site Choice"
          className={classes.speedd}

          icon={<VisibilityIcon />}
          onClose={handleClose}
          onOpen={handleOpen}
          open={open}
          direction="down"
        >

{actions.map((action) => (
            <SpeedDialAction
              id={action.name}
              icon={action.icon}
              tooltipTitle={action.title}
              tooltipPlacement="right"
              tooltipOpen
              onClick={(e) => { handleChange(e,action.name)} }
            />
          ))}



        </SpeedDial>


    );

}