import React  from 'react';

import SvgIcon from '@material-ui/core/SvgIcon';

export default function TentIcon(props) {
    return (
	// eslint-disable-next-line 
      <SvgIcon viewBox="0 0 572.731 572.731" style="enable-background:new 0 0 24 24;" {...props}>

<path d="M572.731,452.183c-9.812-4.256-19.257-8.783-28.413-13.494l19.47-49.91c0.485-1.253,0.432-2.66-0.153-3.884
		s-1.649-2.134-2.943-2.529l-13.938-4.291c-1.277-0.385-2.678-0.23-3.854,0.437c-1.176,0.668-2.009,1.81-2.305,3.127l-5.899,25.712
		C386.838,360.738,290.244,97.851,289.251,95.096l-0.883-2.438c-0.497-2.668-0.721-4.123-0.721-4.123
		c-0.036,0.564-0.109,1.132-0.151,1.696l-0.721-1.992l-2.131,6.966C238.723,245.401,67.598,375.668,28.601,403.797l-5.071-22.159
		c-0.304-1.317-1.138-2.446-2.308-3.127c-1.167-0.68-2.589-0.833-3.866-0.437l-13.929,4.291c-1.294,0.396-2.367,1.318-2.947,2.529
		c-0.582,1.218-0.641,2.631-0.145,3.884l22.945,58.854c-3.948,2.973-6.138,4.551-6.138,4.551h7.917l11.39,29.217
		c0.745,1.903,2.577,3.092,4.528,3.092c0.479,0,0.958-0.071,1.427-0.207c2.435-0.757,3.88-3.251,3.313-5.733l-6.041-26.362h147.545
		c23.69-9.421,91.108-65.598,102.04-148.206c0,0,23.839,116.998,59.533,148.206h175.639l-6.029,26.362
		c-0.573,2.482,0.869,4.977,3.305,5.733c0.473,0.142,0.951,0.207,1.424,0.207c1.957,0,3.783-1.188,4.522-3.092l11.401-29.217
		H572.731z M36.33,437.572l-6.555-28.626c34.859-24.979,202.544-150.996,255.174-299.244
		C259.937,251.333,93.431,392.727,36.33,437.572z M296.066,125.046c29.873,70.154,116.607,249.156,237.548,287.062l-4.262,18.59
		C373.267,343.254,315.234,193.756,296.066,125.046z"/>


      </SvgIcon>
    );
  }