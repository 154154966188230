import React, { useState, useEffect, useRef } from 'react';
import Trip from './trip';
import RecordVisit from './recordVisit';
import Grid from '@material-ui/core/Grid';
import MyInfobox from './infobox';

import EditSite from './editSite';
import CampToolBar from './toolbar';
import SiteSetSelection from './siteSets';
import SiteFeaturesSD from './siteFeaturesSD';



import { makeStyles } from '@material-ui/core/styles';
import {
    useWindowSize
} from '@react-hook/window-size/throttled'







export default function BingMap(props) {

    const [width, height] = useWindowSize({ fps: 60 });

    const useStyles = makeStyles(theme => ({
        bingmap: {
            //     // width: 800,
            width: width,
            height: height - 65,
            marginTop: 0,
        },
        selectList: {
            marginTop: 10,
        },
        titles: {
            fontSize: 32,
            fontWeight: 'strong',
            margin: 10,
            backgroundColor: '#e1f5fe',
            fontStyle: 'oblique',
            borderColor: 'grey'
        },
        directionTitle: {
            fontSize: 14,
            marginTop: 10,
            marginBotton: 10,
            fontWeight: 'strong',
            textAlign: 'center',
        },
        filters: {
            justifyContent: 'center',
            flexGrow: 1,
            flexDirection: 'row',
            backgroundColor: '#e1f5fe',
            alignItems: 'center',
        }

    }));


    const classes = useStyles();
    const mapEl = useRef(null);
    const directionsEl = useRef(null);
    const bingkey = props.bingkey;


    const loaded = props.loaded;
    var retryCount = 0;
    var retryDirCount = 0;


    var mymap = useRef(null);
    var Microsoft = useRef(null);
    var sitepinlayer = useRef(null);
    var visitLayer = useRef(null);
    var newSiteLayer = useRef(null);
    var myLocationLayer = useRef(null);
    var myPlansLayer = useRef(null);
    var tooltip = useRef(null);
    var directionsManager = useRef(null);

    const [sessionKey, setSessionKey] = useState('');


    const api = props.api;
    const loggedIn = props.loggedIn;
    const showme = props.showme;


    const [visits, setVisits] = useState([]);
    const [potentials, setPotentials] = useState([]);
    const [poppos, setPoppos] = useState({ x: -1, y: -1 });

    var tooltipTemplate = '<div style="background-color:white;padding:5px;text-align:center;font-family: Tahoma, Geneva, sans-serif;font-size: 10px;">{title}</div>';

    const [tripList, setTripList] = useState([]);
    const [startLocation, setStartLocation] = useState({});
    const [newSiteLocation, setNewSiteLocation] = useState({});
    const [sites, setSites] = useState([]);
    const [postTripSites, setPostTripSites] = useState([]);

    const [displayEdit, setDisplayEdit] = useState(false);
    const [displayNewSite, setDisplayNewSite] = useState(false);
    const [admin,setAdmin] = useState(false);




    useEffect(() => {


        if (loaded) {
            console.log('in useeffect - loaded');

            renderMap();
        }
        //            showVisits(true);
        // eslint-disable-next-line 
    }, [loaded, api]);

    useEffect(() => {


        if (loggedIn && loaded) {
            console.log('in useeffect - loggedIn');
            setAdmin(api.isAdmin());

            loadVisits();
            loadPotentials();
            // .catch( (err) => { console.log('failed to load: '+err)});

        } else {
            if (!loggedIn) {
                console.log('Not logged in now, clearing data');
                setVisits([]);
                setAdmin(false);
                if (visitLayer.current) {
                    visitLayer.current.clear();
                }
                setPotentials([]);
                if (myPlansLayer.current) {
                    myPlansLayer.current.clear();
                }

                setSites([]);
                if(sitepinlayer.current) {
                    sitepinlayer.current.clear();
                }
                

            }
        }

        // eslint-disable-next-line 
    }, [loggedIn, loaded]);


    useEffect(() => {

        if (window.Microsoft && loggedIn) {
            // ideally now redisplay everything
            console.log('Changing visits');
            mapSites(sites);
        }
        // eslint-disable-next-line 
    }, [visits, sites, potentials, loggedIn]);

    async function loadVisits() {
        return new Promise(async function (resolve, reject) {
            if (api && loggedIn) {

                let v = await api.listMyVisits();
                setVisits(v);


                resolve(true);
            } else {

                reject('IncorrectState');
            }
        });
    }


    async function loadPotentials() {

        return new Promise(async function (resolve, reject) {
            if (api && loggedIn) {

                let v = await api.listMyPotentialVisits();
                setPotentials(v);
                resolve(true);
            } else {
                reject('IncorrectState');
            }
        });
    }

    function renderMap() {

        console.log(window.Microsoft);
        if (window.Microsoft) {
            try {
                Microsoft.current = window.Microsoft;
                console.log(mapEl.current);
                mymap.current = new Microsoft.current.Maps.Map(mapEl.current, {
                    mapTypeId: Microsoft.current.Maps.MapTypeId.road,
                    supportedMapTypes: [Microsoft.current.Maps.MapTypeId.road, Microsoft.current.Maps.MapTypeId.aerial, Microsoft.current.Maps.MapTypeId.grayscale, Microsoft.current.Maps.MapTypeId.ordnanceSurvey]
                });

                mymap.current.getCredentials((c) => {
                    console.log('Here: ' + c);
                    setSessionKey(c);
                });



                sitepinlayer.current = new Microsoft.current.Maps.Layer();
                mymap.current.layers.insert(sitepinlayer.current);

                visitLayer.current = new Microsoft.current.Maps.Layer();
                mymap.current.layers.insert(visitLayer.current);

                myLocationLayer.current = new Microsoft.current.Maps.Layer();
                mymap.current.layers.insert(myLocationLayer.current);

                newSiteLayer.current = new Microsoft.current.Maps.Layer();
                mymap.current.layers.insert(newSiteLayer.current);

                myPlansLayer.current = new Microsoft.current.Maps.Layer();
                mymap.current.layers.insert(myPlansLayer.current);


                //Create an infobox to use as a tooltip when hovering.
                tooltip.current = new Microsoft.current.Maps.Infobox(mymap.current.getCenter(), {
                    visible: false,
                    showPointer: false,
                    showCloseButton: false,
                    offset: new Microsoft.current.Maps.Point(-75, 10)
                });

                tooltip.current.setMap(mymap.current);

                navigator.geolocation.getCurrentPosition(function (position) {
                    var loc = new Microsoft.current.Maps.Location(
                        position.coords.latitude,
                        position.coords.longitude);


                    //Center the map on the user's location.
                    mymap.current.setView({ center: loc, zoom: 8 });



                }, function () { }, {
                    enableHighAccuracy: true,
                    maximumAge: 10000
                });

            } catch (Exception) {
                console.log(Exception);
                if (retryCount < 5) {
                    retryCount++;
                    if (retryCount < 5) {
                        setTimeout(renderMap, 1000);
                    } else {
                        console.log('Retried 5 times, no good');
                    }

                }

            }
        } else {
            console.log('Loaded but not ready');
        }


    }


    function mapSites(site_update) {
        console.log('mapSites ...');
        setSites(site_update);
        addSites(site_update);
        mySites(site_update);
        myPotentialVisits(site_update);
    }

    function addSites(pins) {

        if (sitepinlayer === 'null' || sitepinlayer.current === null) {
            return;
        }

        console.log(sitepinlayer);
        console.log(sitepinlayer.current);

        sitepinlayer.current.clear();
        //if (Microsoft) {



        console.log(pins);
        var pushpins = [];
        pins.forEach(element => {
            var p = new Microsoft.current.Maps.Pushpin(new Microsoft.current.Maps.Location(element.latitude, element.longitude),
                {
                    subTitle: element.title,
                    icon: isPotentialVisit(element) ? "images/potential.svg" : hasVisited(element) ? 'images/check_icon.png' : 'images/' + element.icon
                });

            p.metadata = {
                title: element.title,
                description: element.description ? element.description : element.title,
                latitude: element.latitude,
                longitude: element.longitude,
                icon: element.icon,
                siteset: element.siteset,
                features: element.features ? element.features : [],
                ratings: element.ratings ? { ...element.ratings } : {},
                _id: element._id,
                potential: false,
                category: element.category

            };


            Microsoft.current.Maps.Events.addHandler(p, 'mouseover', pushpinHovered);
            Microsoft.current.Maps.Events.addHandler(p, 'mouseout', closeTooltip);
            Microsoft.current.Maps.Events.addHandler(p, 'click', pushpinClicked);
            pushpins.push(p);


        });

        console.log(pushpins);


        sitepinlayer.current.add(pushpins);


    }

    function hasVisited(val) {

        let a = visits.filter((element) => {
            return element.hasOwnProperty("parentSiteRecordId") && element.parentSiteRecordId === val._id

        })
        return a.length > 0;
    }

    function myPotentialVisits(site_update) {

        if (myPlansLayer.current) {
            myPlansLayer.current.clear();

            var pushpins = [];
            let a = [];
            if (site_update.length > 0) {
                a = potentials.filter((ele) => {
                    return (
                        site_update.filter((ele2) => {
                            return ele2._id === ele.parentSiteRecordId;
                        }).length === 0)
                });
            } else {
                a = potentials;
            }

            console.log(a);

            a.forEach(element => {
                let p = new Microsoft.current.Maps.Pushpin(new Microsoft.current.Maps.Location(element.latitude, element.longitude),
                    {
                        subTitle: element.title,
                        icon: "images/potential.svg"
                    });
                console.log(p);

                p.metadata = {
                    _id: element.parentSiteRecordId ?? element._id,
                    title: element.title,
                    description: element.description ? element.description : element.title,
                    latitude: element.latitude,
                    longitude: element.longitude,
                    icon: "images/potential.svg",
                    features: element.features ? element.features : [],
                    ratings: element.ratings ? { ...element.ratings } : {},
                    potential: true,
                    category: element.category

                };

                Microsoft.current.Maps.Events.addHandler(p, 'mouseover', pushpinHovered);
                Microsoft.current.Maps.Events.addHandler(p, 'mouseout', closeTooltip);
                // was commented out
                Microsoft.current.Maps.Events.addHandler(p, 'click', pushpinClicked);
                pushpins.push(p);


            });

            myPlansLayer.current.add(pushpins);


        }

    }


    function mySites(site_update) {
        if (visitLayer.current) {
            visitLayer.current.clear();

            var pushpins = [];
            let a = [];
            if (site_update.length > 0) {
                a = visits.filter((ele) => {
                    return !ele.hasOwnProperty('parentSiteRecordId') || ele.parentSiteRecordId.length < 1;
                });
            } else {
                a = visits;
            }

            a.forEach(element => {
                let p = new Microsoft.current.Maps.Pushpin(new Microsoft.current.Maps.Location(element.latitude, element.longitude),
                    {
                        subTitle: element.title,
                        icon: 'images/check_icon.png'
                    });

                p.metadata = {
                    _id: element.parentSiteRecordId ?? element._id,
                    title: element.title,
                    description: element.description ? element.description : element.title,
                    latitude: element.latitude,
                    longitude: element.longitude,
                    icon: 'images/check_icon.png',
                    features: element.features ? element.features : [],
                    ratings: element.ratings ? { ...element.ratings } : {},
                    potential: false,
                    category: element.category

                };

                Microsoft.current.Maps.Events.addHandler(p, 'mouseover', pushpinHovered);
                Microsoft.current.Maps.Events.addHandler(p, 'mouseout', closeTooltip);
                Microsoft.current.Maps.Events.addHandler(p, 'click', pushpinClicked);
                pushpins.push(p);


            });

            //console.log(pushpins);


            visitLayer.current.add(pushpins);

        }



    }




    function pushpinHovered(e) {

        console.log(e.target);
        //Make sure the infobox has metadata to display.
        if (e.target.metadata) {

            //Set the infobox options with the metadata of the pushpin.
            tooltip.current.setOptions({
                location: e.target.getLocation(),
                htmlContent: tooltipTemplate.replace('{title}', e.target.metadata.title),
                visible: true
            });
            setTimeout(closeTooltip, 900);
        }
    }

    function closeTooltip() {
        //Close the tooltip.
        if (tooltip.current) {
            tooltip.current.setOptions({
                visible: false
            });
        }
    }


    function pushpinDragged(e) {

        setStartLocation({ title: 'My Location', location: e.target.getLocation() });

    }

    function pushpinClicked(e) {


        setPoppos({
            x: e.pageX,
            y: e.pageY,
            location: e.target.getLocation(),
            title: e.target.metadata.title,
            description: e.target.metadata.description,
            features: e.target.metadata.features,
            ratings: { ...e.target.metadata.ratings },
            _id: e.target.metadata._id,
            icon: e.target.metadata.icon,
            potential: e.target.metadata.potential,
            visited: e.target.metadata.visited,
            category: e.target.metadata.category


        });

        closeTooltip();

    }

    function addToTrip(site) {
        console.log('Adding to trip');
        console.log(site);
        console.log(tripList);
        if (tripList.length === 0 || tripList[(tripList.length - 1)].title !== site.title) {
            setTripList(tripList => [...tripList, site]);
        }

    }

    function existingVisit(val) {

        let a = visits.filter((element) => {
            return val.parentSiteRecordId === element.parentSiteRecordId && val.visited === true

        })
        return a.length > 0;
    }


    function recordVisit(site) {
        console.log('Site Visited');

        // Check if site visited - if so don't add

        if (!existingVisit(site)) {

            var s = {
                title: site.title,
                description: site.description,
                latitude: site.location.latitude,
                longitude: site.location.longitude,
                features: site.features,
                ratings: { ...site.ratings },
                visited: site.visited,
                potential: site.potential,
                category: site.category,
                parentSiteRecordId: site.parentSiteRecordId
            };
            setVisits(visits => [...visits, s])
            api.addMyVisit(s);

        }

    }

    function getStart() {
        console.log('Get Start');
        navigator.geolocation.getCurrentPosition(function (position) {
            console.log('Got loc');
            var loc = new Microsoft.current.Maps.Location(
                position.coords.latitude,
                position.coords.longitude);
            setTripList(tripList => [{ title: 'My Location', location: loc }, ...tripList]);
            setStartLocation({ title: 'My Location', location: loc });
        }, function (e) {
            console.log('failed to get loc');
        }, {
            enableHighAccuracy: true,
            maximumAge: 60000
        });
    }

    function addMyLocation(position) {

        if (myLocationLayer.current) {
            myLocationLayer.current.clear();
        }

        var loc = new Microsoft.current.Maps.Location(
            position.coords.latitude,
            position.coords.longitude);

        //Add a pushpin at the user's location.
        var pin = new Microsoft.current.Maps.Pushpin(loc,
            {
                draggable: true
            });

        pin.metadata = {
            title: 'My Location',
            description: "My Location",
        };

        Microsoft.current.Maps.Events.addHandler(pin, 'mouseover', pushpinHovered);
        Microsoft.current.Maps.Events.addHandler(pin, 'mouseout', closeTooltip);
        Microsoft.current.Maps.Events.addHandler(pin, 'click', pushpinClicked);
        Microsoft.current.Maps.Events.addHandler(pin, 'dragend', pushpinDragged);

        myLocationLayer.current.add(pin);
        setStartLocation({ title: 'My Location', location: loc });

    }

    function displayDirections(trip) {

        setPostTripSites(sites);
        mapSites([]);


        if (Microsoft && Microsoft.current) {
            Microsoft.current.Maps.loadModule('Microsoft.Maps.Directions', function () {
                //Create an instance of the directions manager.
                try {
                    drawDirections(trip);

                    retryDirCount = 0;
                } catch (Excep) {
                    console.log(Excep);
                    if (retryDirCount < 5) {
                        retryDirCount++;
                        if (retryDirCount < 5) {
                            setTimeout(displayDirections, 1000);
                        } else {
                            console.log('Retried Directions 5 times, no good');
                        }

                    }
                }
            });
        } else {
            console.log("um, ms");
            console.log(Microsoft);
            console.log(Microsoft.current);
        }
    }

    function drawDirections(trip) {

        if (directionsManager.current) {
            directionsManager.current.clearAll();
        }
        console.log(trip);

        directionsManager.current = new Microsoft.current.Maps.Directions.DirectionsManager(mymap.current);


        trip.forEach((val) => {
            directionsManager.current.addWaypoint(new Microsoft.current.Maps.Directions.Waypoint({
                address: val.title,
                location: val.location
            }));

        });

        //Specify the element in which the itinerary will be rendered.
        directionsManager.current.setRenderOptions({ itineraryContainer: directionsEl.current });

        //Calculate directions.
        directionsManager.current.calculateDirections();
    }

    function clearTrip() {
        setTripList([]);
        if (directionsManager.current) {
            directionsManager.current.clearAll();
        }
        mapSites(postTripSites);
    }

    function filterResult(sites) {
        console.log(sites);
        addSites(sites);
    }

    function newPin(position, name, bestView) {

        if (newSiteLayer.current) {
            newSiteLayer.current.clear();
        }

        var loc = new Microsoft.current.Maps.Location(
            position.coords.latitude,
            position.coords.longitude);

        //Add a pushpin at the user's location.
        var pin = new Microsoft.current.Maps.Pushpin(loc,
            {
                draggable: true
            });

        pin.metadata = {
            title: name,
            description: "New Site Location",
        };

        console.log(bestView);
        if (bestView) {
            mymap.current.setView({ bounds: Microsoft.current.Maps.LocationRect.fromEdges(...bestView) });
        }

        Microsoft.current.Maps.Events.addHandler(pin, 'dragend', newsiteDragged);

        newSiteLayer.current.add(pin);
        setNewSiteLocation({ title: 'New Site Location', location: loc });

    }

    function newsiteDragged(e) {

        setNewSiteLocation({ title: 'New Site Location', location: e.target.getLocation() });

    }

    function newPinLocation() {

        return (newSiteLocation.location);

    }

    function addNewSite(site) {

        var s = {
            title: site.name,
            latitude: newSiteLocation.location.latitude,
            longitude: newSiteLocation.location.longitude,
            description: site.description,

            features: [
                { name: "wifi", value: site.wifi },
                { name: "facilities", value: site.facilities },
                { name: "hardstanding", value: site.hardstanding },
                { name: "hookup", value: site.hookup },
                { name: "adult", value: site.adult },
                { name: "child", value: site.child },
                { name: "serviced", value: site.serviced },
                { name: "pets", value: site.pets },
                { name: "accessible", value: site.accessible }
            ],
            category: 'other',
            icon: 'icon-new.png'
        };


        api.createNewSite(s);

        removeNewPin();
        setDisplayNewSite(false);

    }

    function removeNewPin() {
        if (newSiteLayer.current) {
            newSiteLayer.current.clear();
        }
        setDisplayNewSite(false);

    }

    function selectedLocation(a) {

        if (a) {
            console.log(a);
            mymap.current.setView({ bounds: Microsoft.current.Maps.LocationRect.fromEdges(...a.bestView) });
        }
    }

    function editSite() {

        if (poppos.category.length > 0) {

            setDisplayEdit(true);
            console.log(JSON.stringify(poppos));
        }

    }

    function updateSite(update) {

        let togo = {
            title: update.title,
            description: update.description,
            _id: update._id,
            features: [
                { name: "wifi", value: update.wifi },
                { name: "facilities", value: update.facilities },
                { name: "hardstanding", value: update.hardstanding },
                { name: "hookup", value: update.hookup },
                { name: "adult", value: update.adult },
                { name: "child", value: update.child },
                { name: "serviced", value: update.serviced },
                { name: "pets", value: update.pets },
                { name: "accessible", value: update.accessible }
            ],
            latitude: poppos.location.latitude,
            longitude: poppos.location.longitude,
            category: poppos.category,
            icon: poppos.icon

        };

        console.log(togo);
        localUpdateSite(togo)
        api.updateSite(togo);
        setDisplayEdit(false);

    }

    function localUpdateSite(site) {

        // site is the changed site

        let a = [];

        sites.forEach((element) => {

            if (site._id === element._id) {
                console.log("Changing " + element.title);
                element = { ...site };
                console.log(element);
            }
            a.push(element);


        });
        setSites(a);
    }

    function clearSite() {
        setDisplayEdit(false);
    }

    function addLocation() {

        navigator.geolocation.getCurrentPosition(function (position) {
            addMyLocation(position);
        }, function (e) {
            console.log('failed to get loc');
        }, {
            enableHighAccuracy: true,
            maximumAge: 60000
        });
    }


    function menuPerform(val) {

        if (val === 0) {
            addLocation();
        } else if (val === 1) {
            setDisplayNewSite(true);
        }

    }

    function isPotentialVisit(site) {


        let a = potentials.filter((element) => {
            return element.hasOwnProperty("parentSiteRecordId") && element.parentSiteRecordId === site._id

        })
        return a.length > 0

    }


    function existingIdea(val) {

        let a = potentials.filter((element) => {
            return val.parentSiteRecordId === element.parentSiteRecordId

        })
        return a.length > 0;
    }



    function addPotentialVisit(site) {

        console.log('Potential Visit')


        // Check if site visited - if so don't add

        if (!existingIdea(site)) {

            var s = {
                title: site.title,
                description: site.description,
                latitude: site.location.latitude,
                longitude: site.location.longitude,
                features: site.features,
                ratings: { ...site.ratings },
                visited: site.visited,
                potential: site.potential,
                category: site.category,
                parentSiteRecordId: site.parentSiteRecordId
            };
            setPotentials(potentials => [...potentials, s])  //was site
            api.addPotentialVisit(s);

        }




    }

    function removeVisit(site) {

        console.log('Remove Visit');

        let a = visits.filter((val) => {
            return val.parentSiteRecordId !== site.parentSiteRecordId
        });


        setVisits(a);
        api.removeVisit(site);

    }

    function removePotentialVisit(site) {

        console.log('Remove Potential Visit')

        let a = potentials.filter((val) => {
            return val.parentSiteRecordId !== site.parentSiteRecordId
        });


        setPotentials(a);
        api.removePotentialVisit(site);

    }

    function fetchFreskKey() {
        console.log('Session key expired, resulting to bingkey');
        setSessionKey(bingkey);

    }



    return (

        <Grid container>
            <CampToolBar
                showme={showme}
                api={api}
                loggedIn={loggedIn}
                perform={menuPerform}
                search={selectedLocation}
                loaded={loaded}
                mapme={mapSites}
                sessionKey={sessionKey}
                fetchFreshKey={fetchFreskKey}

            />
            <span hidden={displayEdit || displayNewSite || tripList.length > 0}>
                <SiteSetSelection tellme={mapSites} api={api} ></SiteSetSelection>
            </span>
            <span hidden={displayEdit || displayNewSite || tripList.length > 0 || sites.length < 1}>
                <SiteFeaturesSD sites={sites} tellme={filterResult} />
            </span>


            <Grid container >

                <Grid item hidden={displayEdit || displayNewSite || tripList.length > 0 ? false : true}>
                    <EditSite
                        pinLocation={newPinLocation}
                        updateSite={updateSite}
                        clearSite={clearSite}
                        current={poppos}
                        loaded={loaded}
                        display={displayEdit}
                    />

                    {displayNewSite &&
                        <RecordVisit
                            dropPin={newPin}
                            pinLocation={newPinLocation}
                            addSiteVisit={addNewSite}
                            removePin={removeNewPin}
                            loaded={loaded}
                            api={api}
                            sessionKey={sessionKey}
                            fetchFreshKey={fetchFreskKey}
                            loggedIn={loggedIn}
                        />
                    }

                    {tripList.length > 0 &&
                        <div>
                            <Trip
                                sites={tripList}
                                getStart={getStart}
                                start={startLocation}
                                tell={displayDirections}
                                clear={clearTrip}  ></Trip>
                            <div ref={directionsEl}></div>
                        </div>
                    }

                </Grid>
                <Grid item xs={displayEdit || displayNewSite || tripList.length > 0 ? 8 : 12} >
                    <div ref={mapEl} className={classes.bingmap} ></div>
                    <MyInfobox
                        popper={poppos}
                        trip={addToTrip}
                        record={recordVisit}
                        edit={editSite}
                        hasVisited={hasVisited}
                        isPotentialVisit={isPotentialVisit}
                        addPotentialVisit={addPotentialVisit}
                        removePotentialVisit={removePotentialVisit}
                        removeVisit={removeVisit}
                        loggedIn={loggedIn}
                        api={api}
                        admin={admin}
                    />
                </Grid>



            </Grid>
        </Grid>

    );

}