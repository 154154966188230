import React, { Component } from 'react';

import Snackbar from '@material-ui/core/Snackbar';
import Alert from '@material-ui/lab/Alert';


import Sites from './components/sites';
import Welcome from './components/welcome';
import SignUpIn from './components/signupin';
import SignIn from './components/signin';

import AwsApiClient from './services/api_aws';



import { BrowserRouter as Router, Route, Redirect } from "react-router-dom";

//import env from "@beam-australia/react-env";

import './App.css';

// import MUICookieConsent from 'material-ui-cookie-consent';


import env from "@beam-australia/react-env";
//const msal = require('msal');
//import msal from '@azure/msal-browser';

const BINGKEY = env("BINGKEY");




function inIframe() {
  try {
    return window.self !== window.top;
  } catch (e) {
    return true;
  }
};

//var reactPlugin = new ReactPlugin();
//var reactPlugin = new ReactPlugin();


// Configuration object constructed

// function loggerCallback(logLevel, message, containsPii) {
//   console.log(message);
// }




class App extends Component {



  constructor(props) {
    super(props);
    this.showme = this.showme.bind(this);
    this.state = {
      location: 'home',
      snackmessage: '',
      snackseverity: '',
      snackopen: false,
      loggedIn: false,
      bingkey: BINGKEY,
      authProvider: props.auth
    };

    this.retry = 0;
    this.setLoggedIn = this.setLoggedIn.bind(this);


    //this.api.login();
    this.snack = this.snack.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.testLoggedIn = this.testLoggedIn.bind(this);

  }

  componentDidMount() {
    console.log('about to start api');
    this.api = new AwsApiClient(this.setLoggedIn);
    console.log(this.api);
  }


  setLoggedIn(val) {
    console.log('Logging in: ' + val);
    if (val !== false) {
      this.retry = 0;
      this.testLoggedIn();
    } else {
      this.setState({ loggedIn: false });
    }
  }

  testLoggedIn() {
    this.api.isLoggedIn()
      .then((res) => {
        if (res) {
          this.setState({ loggedIn: res });
        } else {
          this.retry++;
          if (this.retry <= 10) {
            console.log('Login is delayed, retry in 1s');
            setTimeout(this.testLoggedIn, 1000);
          }
        }
      });
  }

  showme(val) {
    console.log(val);
    //    this.snack(val)
    this.setState({ location: val });
  }


  snack(message, severity) {
    this.setState({ snackmessage: message });
    this.setState({ snackseverity: severity });
    this.setState({ snackopen: true });
  }

  handleClose(event, reason) {
    if (reason === 'clickaway') {
      return;
    }

    this.setState({ snackopen: false });
  };






  render() {
    return (!inIframe() &&
      <div className="App">




        <div>

          <Snackbar open={this.state.snackopen} autoHideDuration={6000} onClose={this.handleClose} anchorOrigin={{ horizontal: 'center', vertical: 'top' }}>
            <Alert elevation={6} variant="filled" onClose={this.handleClose} severity={this.state.snackseverity}>
              {this.state.snackmessage}
            </Alert>
          </Snackbar>
        </div>



        <Router>
          <div>
            <Route
              exact path="/"
              render={(props) => <Welcome {...props} showme={this.showme} api={this.api} loggedIn={this.state.loggedIn}
              />}
            />


            <Route
              exact path="/sites"
              render={(props) => <Sites {...props} showme={this.showme} api={this.api} loggedIn={this.state.loggedIn} bingkey={this.state.bingkey}
              />}
            />


            <Route
              exact path="/signupin"
              render={(props) => <SignUpIn {...props} showme={this.showme} api={this.api} loggedIn={this.state.loggedIn}
              />}
            />

            <Route
              exact path="/signin"
              render={(props) => <SignIn {...props} showme={this.showme} api={this.api} loggedIn={this.state.loggedIn}
              />}
            />

            {this.state.location === 'home' && <Redirect to='/' />}
            {this.state.location === 'sites' && <Redirect to='/sites' />}
            {this.state.location === 'signupin' && <Redirect to='/signupin' />}
            {this.state.location === 'signin' && <Redirect to='/signin' />}



          </div>

        </Router>
      </div>

    );
  }

}


export default App;
//export default withAITracking(reactPlugin, App);