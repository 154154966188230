import React, { useState } from 'react';

import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepButton from '@material-ui/core/StepButton';

import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import Collapse from '@material-ui/core/Collapse';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import TextField from '@material-ui/core/TextField';


import WcIcon from '@mui/icons-material/Wc';
import WifiIcon from '@mui/icons-material/Wifi';
import SignalWifi0BarIcon from '@mui/icons-material/SignalWifi0Bar';
import PowerIcon from '@mui/icons-material/Power';
import PowerOffIcon from '@mui/icons-material/PowerOff';
import GrassIcon from '../icons/grass';
import { green, blue } from '@material-ui/core/colors';
import DashboardIcon from '@mui/icons-material/Dashboard';
import ChildFriendlyIcon from '@mui/icons-material/ChildFriendly';
import PeopleIcon from '@mui/icons-material/People';
import InvertColorsIcon from '@mui/icons-material/InvertColors';
import InvertColorsOffIcon from '@mui/icons-material/InvertColorsOff';
import PetsIcon from '@mui/icons-material/Pets';
import AccessibleForwardIcon from '@mui/icons-material/AccessibleForward';
import Checkbox from '@material-ui/core/Checkbox';

import SearchLocation from './searchLocation';
import NotListedLocationIcon from '@mui/icons-material/NotListedLocation';

import { makeStyles } from '@material-ui/core/styles';
import { Button } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
    typography: {
        padding: theme.spacing(2),
    },
    title: {
        fontSize: 12,
        fontWeight: "bolder",
        fontStyle: "oblique",
        justifyContent: "left",
        verticalAlign: 'middle',
        margin: 'auto',
        width: '90%',
        textAlign: 'center',
    },
    actions: {
        borderTopWidth: 1,
        borderTopColor: 'grey',
        borderLeftWidth: 1,
        borderLeftColor: 'grey',
        borderRightWidth: 1,
        borderRightColor: 'grey',
        justifyContent: 'center',
        verticalAlign: 'middle',
    },
    options: {
        fontSize: 12,
    },
    rating: {
        marginTop: 10,
    },
    placement: {
        marginTop: 10,
    }
}));

function getSteps() {
    return ['Name', 'Location', 'Features', 'Save'];
}

export default function RecordVisit(props) {

    const dropPin = props.dropPin;
    const pinLocation = props.pinLocation;
    const addSiteVisit = props.addSiteVisit;
    const removePin = props.removePin;
    const api = props.api;
    let loaded = props.loaded;
    const sessionKey = props.sessionKey;
    const fetchFreshKey = props.fetchFreshKey;
    const loggedIn = props.loggedIn;


    const [state, setState] = useState({
        wifi: true,
        facilities: false,
        hardstanding: false,
        hookup: true,
        adult: true,
        child: false,
        serviced: true,
        pets: false,
        accessible: false,
        name: '',
        description: ''

    });


    function handleChange(event) {
        var a = { ...state, [event.target.name]: event.target.checked };
        setState(a);

    };



    function handleName(value) {
        var a = { ...state, name: value };
        setState(a);
    }

    function handleDescription(value) {
        var a = { ...state, description: value };
        setState(a);
    }

    function tellDropPin(location, name, bestView) {
        dropPin(location, name, bestView);

    }

    function askPinLocation() {

        let loc = pinLocation();
        console.log(loc);

    }

    function tell() {
        addSiteVisit(state);

        setState(
            {
                wifi: true,
                facilities: false,
                hardstanding: false,
                hookup: true,
                adult: true,
                child: false,
                serviced: true,
                pets: false,
                accessible: false,
                name: '',
                description: ''

            }
        );
    }

    function clear() {
        removePin();
        setState(
            {
                wifi: true,
                facilities: false,
                hardstanding: false,
                hookup: true,
                adult: true,
                child: false,
                serviced: true,
                pets: false,
                accessible: false,
                name: '',
                description: '',
                rating: 5,
                accessRating: 5,
                pitchRating: 5

            });

    }

    return (
        <RecordVisitChild
            setFeatures={handleChange}
            setName={handleName}
            setDescription={handleDescription}
            dropPin={tellDropPin}
            pinPosition={askPinLocation}
            save={tell}
            features={state}
            loaded={loaded}
            clear={clear}
            api={api}
            sessionKey={sessionKey}
            fetchFreshKey={fetchFreshKey}
            loggedIn={loggedIn}
        />
    );
}

function RecordVisitChild(props) {

    const classes = useStyles();
    const [open, setOpen] = useState(true);

    const handleClick = () => {
        setOpen(!open);
    };

    const state = props.features;
    const setFeatures = props.setFeatures;

    const setName = props.setName;
    const setDescription = props.setDescription;
    const dropPin = props.dropPin;

    const saveSite = props.save;
    const loaded = props.loaded;
    const clearAction = props.clear;
    const api = props.api;
    const sessionKey = props.sessionKey;
    const loggedIn=props.loggedIn;
    const fetchFreshKey = props.fetchFreshKey;

    console.log(loaded);


    const handleChange = (event) => {
        setFeatures(event);
    };


    const handleNameChange = (event) => {
        setName(event.target.value);
        handleComplete();
    }

    const handleDescriptionChange = (event) => {
        setDescription(event.target.value);
    }



    const [activeStep, setActiveStep] = useState(0);
    const [completed, setCompleted] = useState(new Set());
    const steps = getSteps();


    const totalSteps = () => {
        return getSteps().length;
    };


    const allStepsCompleted = () => {
        return isStepComplete(0) && isStepComplete(1);
    };


    const handleStep = (step) => () => {
        setActiveStep(step);
    };

    const handleComplete = () => {
        const newCompleted = new Set(completed);
        newCompleted.add(activeStep);
        setCompleted(newCompleted);


    };


    function isStepComplete(step) {
        return completed.has(step);
    }

    function nextStep() {
        if (activeStep < totalSteps() - 1) { setActiveStep(activeStep + 1); }
    }

    function previousStep() {
        if (activeStep > 0) { setActiveStep(activeStep - 1); }
    }

    function placeNewSitePin() {
        navigator.geolocation.getCurrentPosition(function (position) {
            dropPin(position, state.name);
            handleComplete();
        }, function (e) {
            console.log('failed to get loc');
        }, {
            enableHighAccuracy: true,
            maximumAge: 60000
        });

    }

    function selectedLocation(res) {
        console.log(res.location);
        var a = {
            coords: {
                ...res.location
            }
        };
        console.log(res.bestView);
        dropPin(a, state.name, res.bestView);
        handleComplete();
    }

    function save() {
        if (allStepsCompleted()) {
            saveSite();
            setOpen(false);
            setActiveStep(0);
            setCompleted(new Set());
        }
    }

    function clear() {
        clearAction();
        setOpen(false);
        setActiveStep(0);
        setCompleted(new Set());
    }


    return (
        <>
            <Card variant="outlined">
                <div onClick={handleClick} className={classes.title}>
                    {!open &&

                        <Typography >Add a new site </Typography>
                    }
                    {open &&
                        <Typography >Hide me</Typography>
                    }


                </div>

                <Collapse in={open} timeout="auto" unmountOnExit>
                    <Stepper activeStep={activeStep} alternativeLabel nonLinear>
                        {steps.map((label, index) => {
                            return (
                                <Step key={label}>
                                    <StepButton onClick={handleStep(index)}
                                        completed={isStepComplete(index)}>{label}</StepButton>
                                </Step>
                            );
                        })}
                    </Stepper>

                    <FormGroup>

                        <CardContent>
                            {activeStep === 0 &&
                                <>
                                    <FormGroup row>
                                        <TextField required id="sitename" label="Required" helperText="Site Name" variant="filled" value={state.name} onChange={(e) => handleNameChange(e)} />
                                    </FormGroup>

                                    <FormGroup row>
                                        <TextField id="sitedesc" helperText="Site Description" variant="filled" multiline value={state.description} onChange={(e) => handleDescriptionChange(e)}
                                            rows={3} />
                                    </FormGroup>
                                </>
                            }

                            {activeStep === 1 &&
                                <>
                                    {!isStepComplete(activeStep) &&
                                        <FormGroup>
                                            <FormGroup row className={classes.placement}>
                                                <SearchLocation 
                                                    loaded={loaded} 
                                                    tell={selectedLocation} 
                                                    api={api} 
                                                    sessionKey = {sessionKey}
                                                    loggedIn = {loggedIn} 
                                                    fetchFreshKey={fetchFreshKey}
                                                    />
                                            </FormGroup>
                                            <FormGroup row className={classes.placement}>
                                                <Typography>Or drop and drag a pin!</Typography>
                                                <Button onClick={() => { placeNewSitePin() }}><NotListedLocationIcon />
                                                </Button>

                                            </FormGroup>
                                        </FormGroup>
                                    }
                                    {isStepComplete(activeStep) &&
                                        <Typography>Move Pin To Refine Location</Typography>
                                    }
                                </>
                            }



                            {activeStep === 2 &&
                                <>
                                    <FormGroup row>
                                        <FormControlLabel
                                            control={<Checkbox icon={<SignalWifi0BarIcon />} checkedIcon={<WifiIcon />} checked={state.wifi} onChange={handleChange} name="wifi" />}
                                            label={<Typography className={classes.options}>Wifi</Typography>}
                                            labelPlacement="bottom"
                                        />
                                        <FormControlLabel
                                            control={<Checkbox icon={<PowerOffIcon />} checkedIcon={<PowerIcon />} checked={state.hookup} onChange={handleChange} name="hookup" />}
                                            label={<Typography className={classes.options}>Hookup</Typography>}
                                            labelPlacement="bottom"
                                        />
                                        <FormControlLabel
                                            control={<Checkbox checkedIcon={<WcIcon />} checked={state.facilities} onChange={handleChange} name="facilities" />}
                                            label={<Typography className={classes.options}>Facilities</Typography>}
                                            labelPlacement="bottom"
                                        />
                                    </FormGroup>
                                    <FormGroup row>
                                        <FormControlLabel
                                            control={<Checkbox icon={<GrassIcon style={{ color: green[500] }} />} checkedIcon={<DashboardIcon />} checked={state.hardstanding} onChange={handleChange} name="hardstanding" />}
                                            label={<Typography className={classes.options}>Pitch</Typography>}
                                            labelPlacement="bottom"
                                        />
                                        <FormControlLabel
                                            control={<Checkbox checkedIcon={<ChildFriendlyIcon style={{ color: green[500] }} />} checked={state.child} onChange={handleChange} name="child" />}
                                            label={<Typography className={classes.options}>Children</Typography>}
                                            labelPlacement="bottom"
                                        />
                                        <FormControlLabel
                                            control={<Checkbox checkedIcon={<PeopleIcon style={{ color: green[500] }} />} checked={state.adult} onChange={handleChange} name="adult" />}
                                            label={<Typography className={classes.options}>Adults</Typography>}
                                            labelPlacement="bottom"
                                        />
                                    </FormGroup>
                                    <FormGroup row>
                                        <FormControlLabel
                                            control={<Checkbox icon={<InvertColorsOffIcon />} checkedIcon={<InvertColorsIcon style={{ color: blue[500] }} />} checked={state.serviced} onChange={handleChange} name="serviced" />}
                                            label={<Typography className={classes.options}>Service</Typography>}
                                            labelPlacement="bottom"
                                        />
                                        <FormControlLabel
                                            control={<Checkbox checkedIcon={<PetsIcon style={{ color: blue[500] }} />} checked={state.pets} onChange={handleChange} name="pets" />}
                                            label={<Typography className={classes.options}>Pets</Typography>}
                                            labelPlacement="bottom"
                                        />
                                        <FormControlLabel
                                            control={<Checkbox checkedIcon={<AccessibleForwardIcon style={{ color: blue[500] }} />} checked={state.accessible} onChange={handleChange} name="accessible" />}
                                            label={<Typography className={classes.options}>For All</Typography>}
                                            labelPlacement="bottom"
                                        />


                                    </FormGroup>
                                </>


                            }

                        </CardContent>

                        <CardActions className={classes.actions} >
                            {activeStep === 3 &&
                                <>
                                    <Button variant="outlined" onClick={() => { save() }}>Add new site</Button>

                                </>
                            }

                        </CardActions>

                        <CardActions className={classes.actions} >

                            <>
                                <Button variant="outlined" size="small" onClick={() => { clear() }}>Cancel</Button>

                                <Button variant="outlined" size="small" onClick={() => { previousStep() }}>Previous</Button>
                                <Button variant="outlined" size="small" onClick={() => { nextStep() }}>Next</Button>

                            </>


                        </CardActions>

                    </FormGroup>
                </Collapse>
            </Card>
        </>

    );
}