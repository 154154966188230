import React, { useState } from 'react';


import RegisterToolbar from './registerToolbar'
import { TextField, Button } from '@material-ui/core';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';

import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import CardMedia from '@material-ui/core/CardMedia';

import FormControl from '@mui/material/FormControl';
import Input from '@mui/material/Input';
import InputLabel from '@mui/material/InputLabel';

import Alert from '@mui/material/Alert';

import { makeStyles } from '@material-ui/core/styles';


const useStyles = makeStyles((theme) => ({
    cards: {
        padding: theme.spacing(2),
        margin: 10,
        flexDirection: 'row',
        alignContent: 'center',
        alignItems: 'center',
        minWidth: 270,
    },
    root: {
        flexGrow: 1,

        marginTop: 80,
    },
    greenButton: {
        color: 'green',
    },
    blueButton: {
        color: 'blue',
    },
    typography: {
        padding: theme.spacing(2),
    },
    options: {
        fontSize: 12,
    },
    divider: {
        margin: 15,
    }
})
);


export default function SignIn(props) {

    const showme = props.showme;
    let api = props.api;

    const classes = useStyles();
    const [details, setDetails] = useState({
        name: '',
        password: ''
    });

    const [err, setErr] = useState(0);

    const [showPassword, setShowPassword] = React.useState(false);

    const handleClickShowPassword = () => setShowPassword((show) => !show);

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };



    function handleChange(event) {
        var a = { ...details, [event.target.id]: event.target.value };
        setDetails(a);

    };

    async function signIn() {

        setErr(0);

        let res = await api.signin(details.name, details.password);

        if (res === -1) {
            setErr(1);
        } else {

            showme('sites');
        }

    }


    function menuPerform(val) {

        if (val === 0) {

        } else if (val === 1) {

        }

    }


    return (
        <>
            <RegisterToolbar
                showme={showme}
                perform={menuPerform}

            />

            {err === 1 &&
                <Alert severity="error">Please check and retry!</Alert>
            }

            <div className={classes.overall}>
                <Grid container spacing={1} className={classes.root}>
                    <Grid item xs={12}>
                        <Typography variant="h3">Sign in</Typography>
                    </Grid>

                </Grid>

                <p />


                <Grid container justify="space-evenly" alignItems="stretch" spacing={6} direction="row" className={classes.root}>
                    <Card variant="outlined" className={classes.cards}>
                        <CardMedia
                            className={classes.media}
                        />
                        <CardHeader title="Sign In" subheader="Sign in to see your breadcrumbs" />
                        <CardContent>

                            <TextField
                                label="Username"
                                id="name"
                                helperText=" "
                                style={{ margin: 8 }}
                                placeholder="User name"
                                //                fullWidth
                                margin="normal"
                                required={true}
                                value={details.name}
                                onChange={handleChange}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                            />



                            <FormControl sx={{ m: 1, width: '25ch' }} variant="filled">
                                <InputLabel htmlFor="filled-adornment-password">Password</InputLabel>
                                <Input
                                    id="password"
                                    type={showPassword ? 'text' : 'password'}
                                    endAdornment={
                                        <InputAdornment position="end">
                                            <IconButton
                                                aria-label="toggle password visibility"
                                                onClick={handleClickShowPassword}
                                                onMouseDown={handleMouseDownPassword}
                                                edge="end"
                                            >
                                                {showPassword ? <VisibilityOff /> : <Visibility />}
                                            </IconButton>
                                        </InputAdornment>
                                    }
                                    value={details.password}
                                    onChange={handleChange}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                />
                            </FormControl>

                        </CardContent>



                        <CardActions>
                            <Button variant="filled" className={classes.blueButton} fullWidth={true} onClick={() => { signIn() }}>Sign In</Button>
                        </CardActions>

                    </Card>






                </Grid>









            </div>



        </>
    );

}