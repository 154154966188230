import React, { useState, useEffect } from 'react';

import Link from '@material-ui/core/Link';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';


import { makeStyles } from '@material-ui/core/styles';
import { CardActionArea } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  selects: {
    fontSize: 12,
    textAlign: 'left',
  },
  selectLabel: {
    textAlign: 'left',

  },
  title: {
    fontSize: 14,
  },
  siteDetails: {
    maxWidth: 500,
  }
}));


export default function SiteDetails(props) {

  const classes = useStyles();
  const site = props.site;
  const api = props.api;
  const [details, setDetails] = useState({});


  useEffect(() => {

    try {

      if (site.title !== 'My Location') {
        getDetails(site.title);
      }

    } catch (e) {
      console.log(e);
    }
// eslint-disable-next-line 
  }, [site]);

  async function getDetails(text) {
    if (site.siteset === 'car_cl') {
      text = text + ' Caravan and Motorhome Club';
    }
    let togo = await api.siteDetails(text,site.location.latitude, site.location.longitude);
    setDetails(togo);

  }



  function getRelatedItems() {
    let togo = [];
    if (details.hasOwnProperty('webPages')) {
      details.webPages.value.forEach((val) => {
        if (val.url.search('www.tripadvisor.co.uk') > 0) {
          togo.push({ site: 'Tripadvisor', url: val.url, snippet: val.snippet, name: 'Tripadvisor ' + val.name });
        }
        if (val.url.search('www.ukcampsite.co.uk') > 0) {
          togo.push({ site: 'UK Campsites', url: val.url, snippet: val.snippet, name: 'UK Campsites ' + val.name });
        }


      });


      if (details.webPages.value[0].hasOwnProperty('deepLinks')) {

        details.webPages.value[0].deepLinks.forEach((val) => {

          if (val.name.search('Book ') > 0) {
            togo.push({ site: 'Bookings', url: val.url, snippet: 'Bookings', name: 'Bookings' });
          }

          if (val.name.search('Reviews') > 0) {
            togo.push({ site: 'Reviews', url: val.url, snippet: 'Reviews', name: 'Reviews' });
          }

        });

      }
    }

    console.log(togo);
    return (
      <div>
        {togo.map((val) =>
          <div>
            <Link variant="body2" href={val.url} rel="noreferrer" target="_blank">{val.name}</Link>
          </div>
        )}
      </div>
    );
  }

  function mainResult() {
    console.log(details);
    if (details.hasOwnProperty('webPages')) {
      if (details.webPages.value.length > 0) {
        return (
          <Card className={classes.siteDetails}>
            <CardContent>
              <Link href={details.webPages.value[0].url} rel="noreferrer" target="_blank"><Typography>{details.webPages.value[0].name}</Typography></Link>

              <Typography variant="body2" color="textSecondary" component="p">{details.webPages.value[0].snippet}</Typography>


            </CardContent>
            <CardActionArea>
              <CardActions>
                {getRelatedItems()}
              </CardActions>
            </CardActionArea>
          </Card>

        );
      }
    } else {
      return (
        <Card className={classes.siteDetails}>
          <CardContent>
            <Typography>{site.description}</Typography>
          </CardContent>
        </Card>
      );
    }
  }



  return (
    <>
      {site &&
        mainResult()
      }


    </>

  );

}