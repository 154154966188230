import env from "@beam-australia/react-env";
import jwt_decode from "jwt-decode";

import {
  AuthFlowType,
  InitiateAuthCommand,
  SignUpCommand,
  ConfirmSignUpCommand,
  CognitoIdentityProviderClient,
} from "@aws-sdk/client-cognito-identity-provider";





const DEFAULT_REGION = env("AWS_DEFAULT_REGION");
const CLIENTID = env("AWS_COGNITO_CLIENTID");
const SECRETHASH = env("AWS_COGNITO_SECRET_HASH");



class AwsApiClient {


 
  tell;


  placeURL = env("PLACE_URL");
  visitURL = env("VISIT_URL");
  ideasURL = env("IDEAS_URL");
  ratingsURL = env("RATINGS_URL");
  detailsURL = env("DETAILS_URL");



  constructor(tellme) {

    console.log(env);

    this.accessToken = undefined;
    this.refreshToken = undefined;
    this.groups = [];
    this.tokenTime = -1;

    this.loggedIn = false;
    this.tellme = tellme;

    this.visit = [];
    this.potential = [];
    this.logProcess = '';
    this.sayTrue = this.sayTrue.bind(this);
    this.checkAndRefresh = this.checkAndRefresh.bind(this);


    this.config = { region: DEFAULT_REGION };

    this.client = new CognitoIdentityProviderClient(this.config);
  }

    sayTrue() {
      console.log('Settledown complete');
      this.tell(true);
    }




    signUp = async (username, password, email) => {

      const command = new SignUpCommand({
        ClientId: CLIENTID,
        SecretHash: SECRETHASH,
        Username: username,
        Password: password,
        UserAttributes: [{ Name: "email", Value: email }],
      });

      return this.client.send(command);
    };

    verify = async (username, code) => {
      const command = new ConfirmSignUpCommand({
        ClientId: CLIENTID,
        Username: username,
        ConfirmationCode: code,
      });

      return this.client.send(command);

    };

    signin = async (username, password) => {

      const command = new InitiateAuthCommand({
        AuthFlow: AuthFlowType.USER_PASSWORD_AUTH,
        AuthParameters: {
          USERNAME: username,
          PASSWORD: password,
        },
        ClientId: CLIENTID,
      });

      let result = await this.client.send(command)
              .catch(err => {
                console.log(err);
                return(-1);
              });

      this.loggedIn = true;
      console.log('Logged in - '+this.loggedIn);
      this.tokenTime = Date.now();
      this.accessToken = result.AuthenticationResult.AccessToken;
      this.refreshToken = result.AuthenticationResult.RefreshToken;
      // decode groups and populate
      let jwt = jwt_decode(this.accessToken);
      this.groups = jwt['cognito:groups']??[];
      console.log("Result is "+ JSON.stringify(result));
      this.tellme(username);
      return(0);
    }


  isLoggedIn = async() => {

    console.log('Logged in - '+this.loggedIn);
    var that=this;

    return new Promise(async function(resolve, reject) {
      resolve(that.loggedIn);
    })

  }

  isAdmin = async() => {

    var that=this;

    if(await this.isLoggedIn()) {

      return that.groups.indexOf('Admin')>=0;

    } else {
      return false;
    }

  }

  checkAndRefresh = async() => {

    if (Date.now() - this.tokenTime <= 3200000) {
      return 0;
    }

    var that = this;

    const command = new InitiateAuthCommand({
      AuthFlow: AuthFlowType.REFRESH_TOKEN_AUTH,
      AuthParameters: {
        REFRESH_TOKEN_AUTH: that.refreshToken,
      },
      ClientId: CLIENTID,
    });

    let result = await this.client.send(command)
            .catch(err => {
              console.log(err);
              that.logout();
              return(-1);
            });

    this.loggedIn = true;
    console.log('Refreshed tokens');
    this.accessToken = result.AuthenticationResult.AccessToken;
    this.refreshToken = result.AuthenticationResult.RefreshToken;
    console.log("Result is "+ JSON.stringify(result));
    return(0);

  }

  logout = () => {
    this.loggedIn = false;
    this.accessToken = '';
    this.refreshToken = '';
    this.groups = [];
    this.tellme(false);
  }

  listMyVisits = async() => {

    var that = this;

    if (!this.loggedIn){
      return ([]);
    }

    this.checkAndRefresh();

    return new Promise(async function (resolve, reject) {

      console.log(that.visitURL);

      fetch(that.visitURL, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'accesstoken' : that.accessToken
        }
      }).then((resp) => resp.json())
        .then(function (data) {

          console.log(data);
          resolve(data);

        });

    });


  }

  listMyPotentialVisits = async() => {
    
    var that = this;

    if (!this.loggedIn){
      return ([]);
    }

    this.checkAndRefresh();

    return new Promise(async function (resolve, reject) {

      console.log(that.ideasURL);

      fetch(that.ideasURL, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'accesstoken' : that.accessToken
        }
      }).then((resp) => resp.json())
        .then(function (data) {

          console.log(data);
          resolve(data);

        });

    });

  }

  siteDetails = async(site, lat, lon) => {

    let url = this.detailsURL + '?search=' + site +"&lat="+lat+"&lon=+"+lon;

    var that = this;

    if (!this.loggedIn){
      return ([]);
    }

    this.checkAndRefresh();

    return new Promise(async function (resolve, reject) {

      console.log(url);

      fetch(url, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'accesstoken' : that.accessToken
        }
      }).then((resp) => resp.json())
        .then(function (data) {

          console.log(data);
          resolve(data);

        });

    });


  }



  addPotentialVisit = async(s) => {

    var that = this;

    if (!this.loggedIn){
      return (false);
    }

    this.checkAndRefresh();

    return new Promise(async function (resolve, reject) {

      console.log(that.ideasURL);

      fetch(that.ideasURL, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'accesstoken' : that.accessToken
        },
        body: JSON.stringify(s)
      }).then((resp) => resp.json())
        .then(function (data) {

          console.log(data);
          resolve(data);

        });

    });


  }

  addMyVisit = async(s) => {

    var that = this;

    if (!this.loggedIn){
      return (false);
    }

    this.checkAndRefresh();

    return new Promise(async function (resolve, reject) {

      console.log(that.visitURL);

      fetch(that.visitURL, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'accesstoken' : that.accessToken
        },
        body: JSON.stringify(s)
      }).then((resp) => resp.json())
        .then(function (data) {

          console.log(data);
          resolve(data);

        });

    });


  }

  removePotentialVisit = async(s) => {

    var that = this;

    if (!this.loggedIn){
      return (false);
    }

    this.checkAndRefresh();

    return new Promise(async function (resolve, reject) {

      console.log(that.ideasURL);

      fetch(that.ideasURL+s.parentSiteRecordId, {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
          'accesstoken' : that.accessToken
        },
        body: JSON.stringify(s)
      }).then((resp) => resp.json())
        .then(function (data) {

          console.log(data);
          resolve(data);

        });

    });


  }

  removeVisit = async(s) => {

    var that = this;

    if (!this.loggedIn){
      return (false);
    }

    this.checkAndRefresh();

    return new Promise(async function (resolve, reject) {

      console.log(that.visitUrl);

      fetch(that.visitURL+s.parentSiteRecordId, {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
          'accesstoken' : that.accessToken
        }
      }).then((resp) => resp.json())
        .then(function (data) {

          console.log(data);
          resolve(data);

        });

    });


  }


  getPlaces = async (s) => {


    //async getPlaces(s) {

    var that = this;

    return new Promise(async function (resolve, reject) {

      console.log(that.placeURL + s);

      fetch(that.placeURL + s, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json'
        }
      }).then((resp) => resp.json())
        .then(function (data) {

          console.log(data);
          resolve(data);

        });

    });


  }

  createNewSite = async(site) => {

    var that = this;

    if (!this.loggedIn){
      return (false);
    }

    this.checkAndRefresh();

    return new Promise(async function (resolve, reject) {

      console.log(that.placeURL);

      fetch(that.placeURL+site.category, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'accesstoken' : that.accessToken
        },
        body: JSON.stringify(site)
      }).then((resp) => resp.json())
        .then(function (data) {

          console.log(data);
          resolve(data);

        });

    });

  }

  updateSite = async(site) => {

    var that = this;

    if (!this.loggedIn){
      return (false);
    }

    this.checkAndRefresh();

    return new Promise(async function (resolve, reject) {

      console.log(that.placeURL+site.category+"/"+site._id);
      console.log(site);

      fetch(that.placeURL+site.category+"/"+site._id, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          'accesstoken' : that.accessToken
        },
        body: JSON.stringify(site)
      }).then((resp) => resp.json())
        .then(function (data) {

          console.log(data);
          resolve(data);

        });

   });

  }




 

  



  // async locationSearch(key, str, ) {

  //   let url = this.locationSearchURL + "&key=" + key + "&search=" + str;
  //   return this.authGet(url);

  // }

  


   overallSiteRating = async(parentId) => {

  //   let url = this.ratingsURL + '&parentId=' + parentId;

  //   var loggedIn = await this.isLoggedIn();
  //   console.log(loggedIn);

  //   if (loggedIn) {

  //     return this.authGet(url);
  //   } else {
  //     return ({});
  //   }

  var that = this;

  if (!this.loggedIn){
    return (false);
  }

  this.checkAndRefresh();

  return new Promise(async function (resolve, reject) {

    console.log(that.ratingsURL + parentId);

    fetch(that.ratingsURL + parentId, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'accesstoken' : that.accessToken
      }
    }).then((resp) => resp.json())
      .then(function (data) {

        console.log(data);
        resolve(data);

      });

  });


  

  }

  


}

export default AwsApiClient;