import React, { useState, useEffect } from 'react';

import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import TextField from '@material-ui/core/TextField';


import WcIcon from '@mui/icons-material/Wc';
import WifiIcon from '@mui/icons-material/Wifi';
import SignalWifi0BarIcon from '@mui/icons-material/SignalWifi0Bar';
import PowerIcon from '@mui/icons-material/Power';
import PowerOffIcon from '@mui/icons-material/PowerOff';
import GrassIcon from '../icons/grass';
import { green, blue } from '@material-ui/core/colors';
import DashboardIcon from '@mui/icons-material/Dashboard';
import ChildFriendlyIcon from '@mui/icons-material/ChildFriendly';
import PeopleIcon from '@mui/icons-material/People';
import InvertColorsIcon from '@mui/icons-material/InvertColors';
import InvertColorsOffIcon from '@mui/icons-material/InvertColorsOff';
import PetsIcon from '@mui/icons-material/Pets';
import AccessibleForwardIcon from '@mui/icons-material/AccessibleForward';
import Checkbox from '@material-ui/core/Checkbox';


import { makeStyles } from '@material-ui/core/styles';
import { Button } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
    typography: {
        padding: theme.spacing(2),
    },
    title: {
        fontSize: 12,
        fontWeight: "bolder",
        fontStyle: "oblique",
        justifyContent: "left",
        verticalAlign: 'middle',
        margin: 'auto',
        width: '90%',
        textAlign: 'center',
    },
    actions: {
        borderTopWidth: 1,
        borderTopColor: 'grey',
        borderLeftWidth: 1,
        borderLeftColor: 'grey',
        borderRightWidth: 1,
        borderRightColor: 'grey',
        justifyContent: 'center',
        verticalAlign: 'middle',
    },
    options: {
        fontSize: 12,
    },
    rating: {
        marginTop: 10,
    },
    placement: {
        marginTop: 10,
    }
}));



export default function EditSite(props) {

    const pinLocation = props.pinLocation;
    const updateSite = props.updateSite;
    const clearSite = props.clearSite;
    const current = props.current;
    const displayMe = props.display;

    let loaded = props.loaded;

    const [state, setState] = useState({});
    const [show, setShow] = useState(true);



    useEffect(() => {

        if (typeof current !== 'undefined') {

            if (typeof current.features !== 'undefined' && current.hasOwnProperty("features")) {


                setState(
                    {
                        _id: current._id,
                        wifi: featureValue(current.features, 'wifi') ?? "false",
                        facilities: featureValue(current.features, 'facilities') ?? "false",
                        hardstanding: featureValue(current.features, 'hardstanding') ?? "false",
                        hookup: featureValue(current.features, 'hookup') ?? "false",
                        adult: featureValue(current.features, 'adult') ?? "false",
                        child: featureValue(current.features, 'child') ?? "false",
                        serviced: featureValue(current.features, 'serviced') ?? "false",
                        pets: featureValue(current.features, 'pets') ?? "false",
                        accessible: featureValue(current.features, 'accessible') ?? "false",
                        title: current.hasOwnProperty("title") ? current.title : "",
                        description: current.hasOwnProperty("description") ? current.description : ""

                    }
                );
            } else {
                setState(
                    {
                        _id: current._id,
                        wifi: "false",
                        facilities: "false",
                        hardstanding: "false",
                        hookup: "false",
                        adult: "false",
                        child: "false",
                        serviced: "false",
                        pets: "false",
                        accessible: "false",
                        title: current.hasOwnProperty("title") ? current.title : "",
                        description: current.hasOwnProperty("description") ? current.description : ""

                    }
                );
            }
        }


        setShow(true);
        // eslint-disable-next-line 
    }, [current]);


    function featureValue(o, key) {

        if (typeof (o) === 'undefined') { return false }

        var v = o.filter((i) => {
            return i.name === key;
        });

        if (v.length > 0) {

            return v[0].value;
        } else {
            return false;
        }
    }

    function handleChange(event) {
        var a = { ...state };
        a[event.target.name] = event.target.checked ? "true" : "false";

        setState(a);

    };


    function handleTitle(value) {
        var a = { ...state, title: value };
        setState(a);
    }

    function handleDescription(value) {
        var a = { ...state, description: value };
        setState(a);
    }



    function askPinLocation() {

        let loc = pinLocation();
        console.log(loc);

    }

    function tell() {
        updateSite(state);



        setShow(false);
    }

    function clear() {

        setShow(false);
        clearSite();

    }

    return (
        <div>
            {displayMe &&
                <EditSiteChild
                    setFeatures={handleChange}
                    setTitle={handleTitle}
                    setDescription={handleDescription}
                    pinPosition={askPinLocation}
                    save={tell}
                    features={state}
                    loaded={loaded}
                    clear={clear}
                    show={show}
                />
            }
        </div>
    );
}

function EditSiteChild(props) {

    const classes = useStyles();
    const [open, setOpen] = useState(true);

    const handleClick = () => {
        setOpen(!open);
    };

    const state = props.features;
    const setFeatures = props.setFeatures;
    const setTitle = props.setTitle;
    const setDescription = props.setDescription;
    const saveSite = props.save;
    const loaded = props.loaded;
    const clearAction = props.clear;
    const show = props.show;

    console.log(loaded);


    const handleChange = (event) => {
        setFeatures(event);
    };


    const handleTitleChange = (event) => {
        setTitle(event.target.value);
    }

    const handleDescriptionChange = (event) => {
        setDescription(event.target.value);
    }






    function save() {

        saveSite();
        setOpen(false);


    }

    function clear() {
        clearAction();
        setOpen(false);
    }


    return (

        <>
            {show &&
                <Card variant="outlined">
                    <div onClick={handleClick} className={classes.title}>
                        {!open &&

                            <Typography >Edit Site</Typography>
                        }
                        {open &&
                            <Typography >Hide me</Typography>
                        }


                    </div>
                    <div>

                        {open &&
                            <FormGroup>

                                <CardContent>
                                    <div>
                                        <FormGroup row>
                                            <TextField required id="sitetitle" label="Required" helperText="Site Name" variant="filled" value={state.title} onChange={(e) => handleTitleChange(e)} />
                                        </FormGroup>

                                        <FormGroup row>
                                            <TextField id="sitedesc" helperText="Site Description" variant="filled" multiline value={state.description} onChange={(e) => handleDescriptionChange(e)}
                                                rows={3} />
                                        </FormGroup>
                                    </div>





                                    <div>
                                        <FormGroup row>
                                            <FormControlLabel
                                                control={<Checkbox icon={<SignalWifi0BarIcon />} checkedIcon={<WifiIcon />} checked={state.wifi === "true"} onChange={handleChange} name="wifi" />}
                                                label={<Typography className={classes.options}>Wifi</Typography>}
                                                labelPlacement="bottom"
                                            />
                                            <FormControlLabel
                                                control={<Checkbox icon={<PowerOffIcon />} checkedIcon={<PowerIcon />} checked={state.hookup === "true"} onChange={handleChange} name="hookup" />}
                                                label={<Typography className={classes.options}>Hookup</Typography>}
                                                labelPlacement="bottom"
                                            />
                                            <FormControlLabel
                                                control={<Checkbox checkedIcon={<WcIcon />} checked={state.facilities === "true"} onChange={handleChange} name="facilities" />}
                                                label={<Typography className={classes.options}>Facilities</Typography>}
                                                labelPlacement="bottom"
                                            />
                                        </FormGroup>
                                        <FormGroup row>
                                            <FormControlLabel
                                                control={<Checkbox icon={<GrassIcon style={{ color: green[500] }} />} checkedIcon={<DashboardIcon />} checked={state.hardstanding === "true"} onChange={handleChange} name="hardstanding" />}
                                                label={<Typography className={classes.options}>Pitch</Typography>}
                                                labelPlacement="bottom"
                                            />
                                            <FormControlLabel
                                                control={<Checkbox checkedIcon={<ChildFriendlyIcon style={{ color: green[500] }} />} checked={state.child === "true"} onChange={handleChange} name="child" />}
                                                label={<Typography className={classes.options}>Children</Typography>}
                                                labelPlacement="bottom"
                                            />
                                            <FormControlLabel
                                                control={<Checkbox checkedIcon={<PeopleIcon style={{ color: green[500] }} />} checked={state.adult === "true"} onChange={handleChange} name="adult" />}
                                                label={<Typography className={classes.options}>Adults</Typography>}
                                                labelPlacement="bottom"
                                            />
                                        </FormGroup>
                                        <FormGroup row>
                                            <FormControlLabel
                                                control={<Checkbox icon={<InvertColorsOffIcon />} checkedIcon={<InvertColorsIcon style={{ color: blue[500] }} />} checked={state.serviced === "true"} onChange={handleChange} name="serviced" />}
                                                label={<Typography className={classes.options}>Service</Typography>}
                                                labelPlacement="bottom"
                                            />
                                            <FormControlLabel
                                                control={<Checkbox checkedIcon={<PetsIcon style={{ color: blue[500] }} />} checked={state.pets === "true"} onChange={handleChange} name="pets" />}
                                                label={<Typography className={classes.options}>Pets</Typography>}
                                                labelPlacement="bottom"
                                            />
                                            <FormControlLabel
                                                control={<Checkbox checkedIcon={<AccessibleForwardIcon style={{ color: blue[500] }} />} checked={state.accessible === "true"} onChange={handleChange} name="accessible" />}
                                                label={<Typography className={classes.options}>For All</Typography>}
                                                labelPlacement="bottom"
                                            />


                                        </FormGroup>
                                    </div>




                                </CardContent>


                                <CardActions className={classes.actions} >

                                    <div>
                                        <Button variant="outlined" size="small" onClick={() => { clear() }}>Cancel</Button>

                                    </div>


                                </CardActions>

                                <CardActions className={classes.actions} >
                                    <div>
                                        <Button variant="outlined" onClick={() => { save() }}>Save Changes</Button>

                                    </div>

                                </CardActions>


                            </FormGroup>
                        }
                    </div>
                </Card>
            }
        </>


    );
}