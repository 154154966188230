import React, { useState, useEffect, useRef } from 'react';

import { makeStyles } from '@material-ui/core/styles';

import Typography from '@material-ui/core/Typography';




import Button from '@material-ui/core/Button';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';

import WcIcon from '@mui/icons-material/Wc';
import WifiIcon from '@mui/icons-material/Wifi';

import PowerIcon from '@mui/icons-material/Power';
import PowerOffIcon from '@mui/icons-material/PowerOff';
import GrassIcon from '../icons/grass';
import { green, blue, red, yellow } from '@material-ui/core/colors';
import DashboardIcon from '@mui/icons-material/Dashboard';
import ChildFriendlyIcon from '@mui/icons-material/ChildFriendly';
import PeopleIcon from '@mui/icons-material/People';
import InvertColorsIcon from '@mui/icons-material/InvertColors';

import PetsIcon from '@mui/icons-material/Pets';
import AccessibleForwardIcon from '@mui/icons-material/AccessibleForward';
import NotListedLocationIcon from '@mui/icons-material/NotListedLocation';
import DirectionsCarIcon from '@mui/icons-material/DirectionsCar';

import Rating from '@material-ui/lab/Rating';
import SaveAltIcon from '@mui/icons-material/SaveAlt';
import { Grid } from '@material-ui/core';
import EditIcon from '@mui/icons-material/Edit';
import SiteDetails from './siteDetails';
import SiteRatings from './siteRatings';

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';




const useStyles = makeStyles((theme) => ({
    typography: {
        padding: theme.spacing(2),
    },
    ratings: {
        fontSize: 12,
        fontWeight: "bolder",
        fontStyle: "oblique",
        justifyContent: 'center',
    },
    actions: {
        borderTopWidth: 1,
        borderTopColor: 'grey',
        borderLeftWidth: 1,
        borderLeftColor: 'grey',
        borderRightWidth: 1,
        borderRightColor: 'grey',
        justifyContent: 'center',
    },
    root: {
        flexGrow: 1,
    },
    menuButton: {
        marginRight: theme.spacing(2),
    },
    title: {
        flexGrow: 1,
        fontSize: 14,
    },

    grow: {
        flexDirection: 'row',
        flexGrow: .5,
    },
    infobox: {
        maxWidth: 500,
    },
    siteRatings: {
        marginTop: 10,
    },

}));

export default function MyInfobox(props) {
    const [open, setOpen] = useState(false);


    const classes = useStyles();
    let x = props.popper.x;
    let y = props.popper.y;
    let title = props.popper.title;
    let description = props.popper.description;
    let admin = props.admin;
    let trip = props.trip;
    let record = props.record;
    let edit = props.edit;
    let hasVisited = props.hasVisited;
    let category = props.popper.category

    let loggedIn = props.loggedIn;
    let location = { ...props.popper.location };
    let features = props.popper.features;
    let ratings = { ...props.popper.ratings };
    let isPotentialPin = props.popper.hasOwnProperty('potential') ? props.popper.potential : false;
    let isPotentialVisit = props.isPotentialVisit;
    let addPotentialVisit = props.addPotentialVisit;
    let removePotentialVisit = props.removePotentialVisit;
    let removeVisit = props.removeVisit;

    let _id = props.popper._id;
    let api = props.api;
    let dialogRef = useRef(null);




    const [myRating, setMyRating] = useState({
        layout: ratings.layout,
        access: ratings.access,
        pitch: ratings.pitch
    });

    function setRating(rating) {
        var a = { ...myRating, [rating.name]: rating.value };
        setMyRating(a);
    }

    const [showRatings, setShowRatings] = useState(false);

    const [myLoc, setMyLoc] = useState(false);
    const [visited, setVisited] = useState(false);
    const [possibleVisit, setPossibleVist] = useState(false);


    useEffect(() => {

        if (props.popper.title === 'My Location') {
            setMyLoc(true);
        } else {
            setMyLoc(false);
        }


        if (x >= 0 && y >= 0) {
            setOpen(true);



            let a = { _id: _id };
            setVisited(hasVisited(a));

            if (visited) {
                setShowRatings(true);
                if (ratings.hasOwnProperty('access')) {
                    setMyRating({
                        layout: ratings.layout,
                        access: ratings.access,
                        pitch: ratings.pitch
                    });
                } else {
                    setMyRating({
                        layout: 0,
                        access: 0,
                        pitch: 0
                    })
                }
            } else {
                setShowRatings(false);
                setMyRating({
                    layout: 5,
                    access: 5,
                    pitch: 5
                });
            }
            setPossibleVist(isPotentialVisit(a));

        }
        // eslint-disable-next-line 
    }, [x, y]);

    const handleClose = () => {
        setOpen(false);
        setShowRatings(false);
    };

    function tellTrip() {
        var site = {
            title: title,
            description: description,
            location: { ...location },
            _id: _id
        };
        trip(site);
        handleClose();
    }

    function addRating() {
        setShowRatings(true);
    }

    function tellRecord() {
        console.log("Recording visit!");
        setShowRatings(false);
        var site = {
            title: title,
            description: description,
            location: { ...location },
            ratings: { ...myRating },
            features: features,
            visited: true,
            potential: false,
            category: category,
            parentSiteRecordId: _id,
        };
        record(site);
    }

    function addPossibleVisit() {
        var site = {
            title: title,
            description: description,
            location: { ...location },
            visited: visited,
            potential: true,
            features: features,
            category: category,
            parentSiteRecordId: _id,
        };
        addPotentialVisit(site);
        setPossibleVist(true);
    }


    function togglePossibleVisit() {
        var site = {
            parentSiteRecordId: _id,

        };
        removePotentialVisit(site);
        setPossibleVist(false);

    }

    function toggleVisit() {

        var site = {
            parentSiteRecordId: _id,

        };
        removeVisit(site);
        setVisited(false);

    }

    function askEdit() {
        edit();
        handleClose();
    }

    // function PaperComponent(props) {
    //     return (
    //         <Draggable handle={dialogRef.current} cancel={'[class*="MuiDialogContent-root"]'}>
    //             <Paper {...props} />
    //         </Draggable>
    //     );
    // }

    let wifi, pitch, facilities, hookup, adult, children, serviced, pets, accessible;

    features && console.log(features);

    if (features) {
        features.forEach((ele) => {

            switch (ele.name) {
                case "wifi": wifi = ele.value === "true" ? <WifiIcon aria-label="wifi" style={{ color: red[600] }} /> : ''; break;
                case "facilities": facilities = ele.value === "true" ? <WcIcon aria-label="facilities" style={{ color: red[600] }} /> : ''; break;
                case "hardstanding": pitch = ele.value === "true" ? <DashboardIcon aria-label="hard standing" /> : <GrassIcon style={{ color: green[500] }} />; break;
                case "hookup": hookup = ele.value === "true" ? <PowerIcon style={{ color: red[600] }} aria-label="electric hookup" /> : <PowerOffIcon />; break;
                case "adult": adult = ele.value === "true" === true ? <PeopleIcon style={{ color: red[600] }} aria-label="adult only" /> : ''; break;
                case "child": children = ele.value === "true" ? <ChildFriendlyIcon style={{ color: green[500] }} aria-label="child friendly" /> : ''; break;
                case "serviced": serviced = ele.value === "true" ? <InvertColorsIcon style={{ color: blue[500] }} aria-label="serviced pitch" /> : ''; break;
                case "pets": pets = ele.value === "true" ? <PetsIcon style={{ color: blue[500] }} aria-label="pets" /> : ''; break;
                case "accessible": accessible = ele.value === "true" ? <AccessibleForwardIcon style={{ color: blue[600] }} aria-label="Accessible by all" /> : ''; break;


                default: break;
            }



        })
    }






    return (


        <Dialog
            open={open}
            onClose={handleClose}
        // PaperComponent={PaperComponent}
        >
            <DialogTitle ref={dialogRef} >


                <AppBar position="relative" color="primary" >
                    <Toolbar>
                        {admin &&
                            <Button onClick={() => { askEdit() }} size="small"><EditIcon /></Button>
                        }
                        <Typography variant="h6" className={classes.title}>
                            {title}
                        </Typography>
                        <Button onClick={() => { tellTrip() }} size="small" alt="Add to Route"><DirectionsCarIcon style={{ color: yellow[50] }} aria-label="Add to Route" /></Button>

                        {!isPotentialPin && !visited && !showRatings && loggedIn &&
                            <Button onClick={() => { addRating() }} size="small" alt="I visited!"><img src='/images/check_icon.png' alt="I visited!" /></Button>
                        }
                        {!isPotentialPin && !visited && showRatings && loggedIn &&
                            <Button onClick={() => { tellRecord() }} size="small" alt="Save"><SaveAltIcon /></Button>
                        }

                        {!isPotentialPin && visited && loggedIn &&
                            <Button onClick={() => { toggleVisit() }} size="small" alt="Save"><img src='/images/check_icon.png' alt="Forget I visited!" /></Button>
                        }
                        {!isPotentialPin && possibleVisit && loggedIn &&
                            <Button onClick={() => { togglePossibleVisit() }} size="small"><NotListedLocationIcon style={{ color: yellow[500] }} aria-label="Possible site to visit" /> </Button>
                        }
                        {!isPotentialPin && !possibleVisit && loggedIn &&
                            <Button onClick={() => { addPossibleVisit() }} size="small"><NotListedLocationIcon aria-label="Add possible site to visit" /> </Button>
                        }
                    </Toolbar>
                </AppBar>


            </DialogTitle>
            <DialogContent>
                <DialogContentText>
                    <Grid container className={classes.infobox}>

                        <Grid container justifyContent="center">
                            <Grid item xs={12}>
                                <SiteDetails api={api} site={props.popper} />
                            </Grid>
                        </Grid>
                        <Grid container direction="column" className={classes.siteRatings}>
                            <SiteRatings api={api} site={props.popper} />

                        </Grid>
                        <Grid container direction="column">
                            {
                                showRatings &&

                                <Grid container direction="row"
                                    justifyContent="space-evenly"
                                    alignItems="flex-start"
                                >
                                    <Grid><Typography className={classes.ratings}>Rated by: Me!</Typography></Grid>
                                    <Grid item>
                                        <Typography className={classes.ratings}>My Layout Rating</Typography>
                                        <Rating name="mylayoutrating" value={myRating.layout} size="small" onChange={(event, newValue) => {
                                            setRating({ name: 'layout', value: newValue });
                                        }} />
                                    </Grid>


                                    <Grid item>
                                        <Typography className={classes.ratings}>My Site Access Rating</Typography>
                                        <Rating name="myaccessrating" value={myRating.access} size="small" onChange={(event, newValue) => {
                                            setRating({ name: 'access', value: newValue });
                                        }} />
                                    </Grid>


                                    <Grid item>
                                        <Typography className={classes.ratings}>My Pitch Rating</Typography>
                                        <Rating name="mypitchrating" value={myRating.pitch} size="small" onChange={(event, newValue) => {
                                            setRating({ name: 'pitch', value: newValue });
                                        }} />
                                    </Grid>
                                </Grid>

                            }
                        </Grid>
                    </Grid>
                </DialogContentText>

            </DialogContent>

            <DialogActions>

                <AppBar position="relative"  >
                    <Toolbar>

                        <div className={classes.grow} />


                        {!myLoc &&
                        
                            <span>
                                 {wifi} {facilities} {pitch} {hookup} {children} {adult} {serviced} {pets} {accessible}
                            </span>
                        }

                    </Toolbar>
                </AppBar>

            </DialogActions>

        </Dialog >



    );
}