import React  from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import CardMedia from '@material-ui/core/CardMedia';
import CardActionArea from '@material-ui/core/CardActionArea';
import Button from '@material-ui/core/Button';



const useStyles = makeStyles((theme) => ({
  cards: {
    padding: theme.spacing(2),
    margin: 10,
    flexDirection: 'row',
    alignContent: 'center',
    alignItems: 'center',
    minWidth: 270,
  },
  root: {
    flexGrow: 1,

    marginTop: 80,
  },
  greenButton: {
    color: 'green',
  },
  overall: {
    backgroundImage: "/images/IMG_1617.jpeg",
  },
  media: {
    height: 140,
  },
}));

export default function Welcome(props) {


  const classes = useStyles();


  function handleClick(event, val) {
    console.log('Selected ', val);
    props.showme(val)
  };

  return (

    <div className={classes.overall}>
      <Grid container spacing={1} className={classes.root}>
        <Grid item xs={12}>
          <Typography variant="h3">Visit Breadcrumbs</Typography>
        </Grid>

      </Grid>

      <Grid container justify="space-evenly" alignItems="stretch" spacing={6} direction="row" className={classes.root}>
        <Card variant="outlined" className={classes.cards}>
          <CardActionArea onClick={(e) => handleClick(e, 'sites')}>

            <CardMedia
              className={classes.media}
              image="./images/IMG_1613.jpeg"
              title="Places"
            />
            <CardHeader title="Places" subheader="Places" />
            <CardContent>

            </CardContent>

          </CardActionArea>


          <CardActions>
            <Button color="primary" fullWidth={true} variant="contained" onClick={(e) => handleClick(e, 'sites')}>Places</Button>
          </CardActions>

        </Card>

        <Card variant="outlined" className={classes.cards}>
          <CardActionArea onClick={(e) => handleClick(e, 'signin')}>
            <CardMedia
              className={classes.media}
              image="./images/IMG_1617.jpeg"
              title="Sign In"
            />
            <CardHeader title="Sign In" subheader="To manage your places" />

            <CardContent>

            </CardContent>
          </CardActionArea>

          <CardActions><Button fullWidth={true} variant="outlined" onClick={(e) => handleClick(e, 'signin')}>Sign In</Button></CardActions>


        </Card>

        <Card variant="outlined" className={classes.cards}>
          <CardActionArea onClick={(e) => handleClick(e, 'signupin')}>
            <CardMedia
              className={classes.media}
              image="./images/IMG_0691.jpeg"
              title="Sign Up"
            />
            <CardHeader title="Sign Up" subheader="To store your breadcrumbs" />

            <CardContent>

            </CardContent>
          </CardActionArea>

          <CardActions><Button fullWidth={true} variant="outlined" onClick={(e) => handleClick(e, 'signupin')}>Sign Up</Button></CardActions>


        </Card>


      </Grid>









    </div>



  );

}