import React from 'react';

import { makeStyles } from '@material-ui/core/styles';

import WcIcon from '@mui/icons-material/Wc';
import WifiIcon from '@mui/icons-material/Wifi';
import SignalWifi0BarIcon from '@mui/icons-material/SignalWifi0Bar';
import PowerIcon from '@mui/icons-material/Power';
import PowerOffIcon from '@mui/icons-material/PowerOff';
import GrassIcon from '../icons/grass';

import DashboardIcon from '@mui/icons-material/Dashboard';
import ChildFriendlyIcon from '@mui/icons-material/ChildFriendly';
import PeopleIcon from '@mui/icons-material/People';
import InvertColorsIcon from '@mui/icons-material/InvertColors';

import PetsIcon from '@mui/icons-material/Pets';
import AccessibleForwardIcon from '@mui/icons-material/AccessibleForward';


import SpeedDial from '@material-ui/lab/SpeedDial';

import SpeedDialAction from '@material-ui/lab/SpeedDialAction';
import { green, blue, grey, red  } from '@material-ui/core/colors'
import SearchIcon from '@mui/icons-material/Search';


const useStyles = makeStyles(theme => ({
    
    options: {
        fontSize: 12,
        flexDirection: 'column',
        flexGrow: 1,
        textAlign: 'center',
       
    },
    title: {
        marginTop: 12,
    },
    speedd: {
        position: 'absolute',
        left: '80px',
        top: '50px',
        zIndex: 2,
    }
    
}));


export default function SiteFeaturesSD(props) {

    const classes = useStyles();
    const tellme = props.tellme;
    const sites = props.sites;

    const [state, setState] = React.useState({
        wifi: false,
        facilities: false,
        hardstanding: false,
        hookup: false,
        adult: false,
        child: false,
        serviced: false,
        pets: false,
        accessible: false
        
    });

    const [open, setOpen] = React.useState(false);

    const actions = [
        { icon: state.wifi?<WifiIcon  style={{ color: red[800] }}/>:<SignalWifi0BarIcon />, name: 'wifi', title: 'Wifi' },
        { icon: state.hookup?<PowerIcon style={{ color: red[800] }}/>:<PowerOffIcon />, name: 'hookup', title: 'Hookup' },
        { icon: state.facilities?<WcIcon style={{ color: blue[800] }}/>:<WcIcon style={{ color: grey[500] }} />, name: 'facilities', title: 'Facilities' },
        { icon: state.hardstanding?<DashboardIcon style={{ color: blue[800] }}/>:<GrassIcon style={{ color: green[800] }}/>, name: 'hardstanding', title: 'Hardstanding' },
        { icon: state.child?<ChildFriendlyIcon style={{ color: red[800] }}/>:<ChildFriendlyIcon style={{ color: grey[500] }}/>, name: 'child', title: 'Child Friendly' },
        { icon: state.adult?<PeopleIcon style={{ color: red[900] }}/>:<PeopleIcon style={{ color: grey[500] }}/>, name: 'adult', title: 'Adults' },
        { icon: state.serviced?<InvertColorsIcon style={{ color: blue[500] }}/>:<InvertColorsIcon style={{ color: grey[500] }}/>, name: 'serviced', title: 'Serviced' },
        { icon: state.pets?<PetsIcon style={{ color: red[800] }}/>:<PetsIcon style={{ color: grey[500] }}/>, name: 'pets', title: 'Pets' },
        { icon: state.accessible?<AccessibleForwardIcon style={{ color: blue[800] }}/>:<AccessibleForwardIcon style={{ color: grey[500] }}/>, name: 'accessible', title: 'Accessible' }
      ];

    const handleChange = (event, name) => {
        var a = { ...state, [name]: !state[name] };
        setState(a);
        alldone(a);
    };

    
    function alldone(a) {
        
        // These are the items sites have to match
        let keys = Object.keys(a);

        let filters = keys.filter((val) => {
            return a[val];
        });

        console.log('filters: '+filters);

        if ( filters.length === 0 ) {
            tellme(sites);
            return;
        }

        // filter the sites
        
        let togo = sites.filter((val) => {

            console.log("Checking "+val.title);

            // val is the site being checked

            // examine the filters one by one against the feature list
            return filters.reduce((result2, chk) => {

                // chk is the filter being checked
                if(result2 === false) {
                    return false;
                }

              
                 return val.features.reduce((result, ele) => {

                    if (result === false){
                        console.log("Shortcut");
                        return false;
                    }


                    if (ele.name === chk) {
                        console.log("Matched name of filter " + ele.name + " Value is " + ele.value);
                        return (ele.value==="false"?false:true); 
                    } else {
                        return (result);
                    }

                },true);
                
            },true);

                     
        });

        tellme(togo);

    }

    const handleClose = () => {
        setOpen(false);
      };
    
      const handleOpen = () => {
        setOpen(true);
      };

    

    return (

<SpeedDial
          ariaLabel="Site Choice"
          className={classes.speedd}

          icon={<SearchIcon />}
          onClose={handleClose}
          onOpen={handleOpen}
          open={open}
          direction="down"
        >

{actions.map((action) => (
            <SpeedDialAction
              id={action.name}
              icon={action.icon}
              tooltipTitle={action.title}
              tooltipPlacement="right"
              onClick={(e) => { handleChange(e,action.name)} }
            />
          ))}



        </SpeedDial>


    );

}