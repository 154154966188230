import React from 'react';
import BingMap from './bingmap';
import Grid from '@material-ui/core/Grid';
import ScriptLoader from './scriptLoader';


export default function Sites(props) {

    const resourceUrl = 'https://www.bing.com/api/maps/mapcontrol?setLang=en&setMkt=en-GB&key=Ah_Cth7eK07QAB91oLHfXoZ0zH7K0OIIqRw46L8DVAREEc-5v3D1INZ7Uebs64ul';

    const showme = props.showme;

    const loggedIn = props.loggedIn;
    const bingkey = props.bingkey;

    var [loaded, error] = ScriptLoader({ url: resourceUrl });

    if (error) {
        
    }

    const api = props.api;


    return (



        <>
           
            <Grid container spacing={1}>

                <Grid item xs={12}>
                    <BingMap api={api} loaded={loaded} loggedIn={loggedIn} showme={showme} bingkey={bingkey}></BingMap>
                </Grid>

            </Grid>

        </>
    );

}