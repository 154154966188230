import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';

import env from "@beam-australia/react-env";
//const msal = require('msal');
//import msal from '@azure/msal-browser';
import * as msal from "@azure/msal-browser";
const tenant = env("TENANT");
const client_id = env("CLIENT_ID");
const target_client = env("TARGET_CLIENT_ID");
const CLIENTNAME = env("CLIENTNAME");  //https://gpnotes.azureedge.net/


const config = {
  auth: {
    clientId: client_id,
    authority: 'https://login.microsoftonline.com/' + tenant,
    validateAuthority: false,
    // postLogoutRedirectUri: 'https://' + SITENAME + '/',
    postLogoutRedirectUri: CLIENTNAME,
    redirectUri: CLIENTNAME,
    
    audience: "https://" + tenant + "/" + target_client
  },
  asyncPopups: true,
  cache: {
    cacheLocation: 'localStorage',
    storeAuthStateInCookie: false,
    
  },
  system: {
    loggerOptions: {
      loggerCallback: (level, message, containsPii) => {
        if (containsPii) {
          return;
        }
        switch (level) {
          case msal.LogLevel.Error:
            console.error(message);
            return;
          case msal.LogLevel.Info:
            console.info(message);
            return;
          case msal.LogLevel.Verbose:
            console.debug(message);
            return;
          case msal.LogLevel.Warning:
            console.warn(message);
            return;
          default:
            console.error(message);
            return;
        }
      }, level: msal.LogLevel.Verbose,
      piiLoggingEnabled: true,
    }
  },
  windowHashTimeout: 60000,
  iframeHashTimeout: 6000,
  loadFrameTimeout: 0

}



const authProvider = new msal.PublicClientApplication(config);

ReactDOM.render(
  <React.StrictMode>
    <App auth={authProvider}/>
  </React.StrictMode>,
  document.getElementById('root')
);


// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
