import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';

import { Typography } from '@material-ui/core';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import SupervisorAccountIcon from '@mui/icons-material/SupervisorAccount';
import Table from '@material-ui/core/Table';

import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';

const useStyles = makeStyles({
    list: {
        width: 450,
    },
    fullList: {
        width: 'auto',
    },
    key: {
        marginTop:50,
    }
});

export default function UserDrawer(props) {
    const classes = useStyles();
    const [state, setState] = React.useState(true);
    const user = props.user;
    //  const user = props.user;
    const admin = props.admin;
    const close = props.close;

    const toggleDrawer = (open) => event => {

        setState(open);
        if (!open) close();
    };



    const display_user_details =
        <>
            <TableContainer component={Paper}>
                <Table className={classes.table} aria-label="simple table" size='small'>
                    <TableHead>
                        <TableRow>
                            <TableCell><Typography variant="h6">User</Typography></TableCell>
                            <TableCell><Typography variant="h6">Role</Typography></TableCell>
                        </TableRow>
                    </TableHead>

                    <TableRow >
                        
                        <TableCell align="left">{user}</TableCell>
                        <TableCell align="left">
                            {admin &&
                         <SupervisorAccountIcon />
                            }
                         { !admin &&
                            <AccountCircleIcon/>
                        }
                        </TableCell>
                    </TableRow>
                </Table>
            </TableContainer>


            <Paper className={classes.key}>
                
                <TableContainer component={Paper}>
                <Table className={classes.table} aria-label="simple table" size='small'>
                    <TableHead>
                        <TableRow>
                            <TableCell><Typography variant="body1">Icon</Typography></TableCell>
                            <TableCell><Typography variant="body1">Role</Typography></TableCell>
                        </TableRow>
                    </TableHead>

                    <TableRow >
                        
                        <TableCell align="left"><SupervisorAccountIcon /></TableCell>
                        <TableCell align="left">Administrator</TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell align="left"><AccountCircleIcon/></TableCell>
                        <TableCell align="left">General User</TableCell>
                    </TableRow>
                </Table>
            </TableContainer>
            </Paper>
            

        </>

    return (
        <>

            <Drawer anchor="right" open={state} onClose={toggleDrawer(false)}>
                {display_user_details}
            </Drawer>
        </>
    );
}