import React, { useState, useEffect } from 'react';

import IconButton from '@material-ui/core/IconButton';
import Box from '@material-ui/core/Box';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import DirectionsIcon from '@mui/icons-material/Directions';
import ClearIcon from '@mui/icons-material/Clear';

import { makeStyles } from '@material-ui/core/styles';

import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { blue  } from '@material-ui/core/colors'



const useStyles = makeStyles(theme => ({
    bingmap: {
        width: 800,
        height: 700,
    },
    selects: {
        fontSize: 12,
        textAlign: 'left',
    },
    selectLabel: {
        textAlign: 'left',

    },
    sites: {
        fontSize: 9,
    },
    buttons: {
        marginLeft: 'auto',
        width: "70%"
    },
    paper: {
        width: '20rem',
    }
}));


export default function Trip(props) {

    const classes = useStyles();
    const sites = props.sites;

    const tell = props.tell;
    const clear = props.clear;

    const [siteList, setSiteList] = useState([]);


    useEffect(() => {


        console.log(JSON.stringify(sites));
        setSiteList(sites);

    }, [sites]);
   

    function clearTrip() {
        clear();
    }

    function tell_trip() {
        tell(siteList);
    }

    function showme() {
        return (sites.length > 0);
    }


    const reorder = (list, startIndex, endIndex) => {
        const result = [...list];
        const [removed] = result.splice(startIndex, 1);
        result.splice(endIndex, 0, removed);

        return result;
    };

    const listSites = siteList.map((value, index) => 

        <Draggable key={value.id} draggableId={value.id} index={index}>
            {(provided, snapshot) => (
                <Paper ref={provided.innerRef}
                    {...provided.draggableProps}
                    {...provided.dragHandleProps}
                    className={classes.paper}>    
                    <Typography gutterBottom='true'>{value.title}</Typography>
                </Paper>
            )}
        </Draggable>

    );

    function onDragEnd(result) {
        if (!result.destination) {
            return;
        }

        const items = reorder(
            siteList,
            result.source.index,
            result.destination.index
        );

        
        setSiteList(items);
    }

    return (
        <>
            {
                showme() &&

                <Card variant="outlined">
                    <CardContent>
                    
                        <Typography className={classes.title} gutterBottom>
                        <IconButton onClick={() => clearTrip()} size="small"><ClearIcon /></IconButton>
                            Directions &nbsp;
                <IconButton onClick={() => tell_trip()} size="small" style={{ color: blue[800] }}><DirectionsIcon /></IconButton>
                            
                        </Typography>

                        <DragDropContext onDragEnd={onDragEnd}>
                            <Droppable droppableId="droppable">
                                {
                                    (provided, snapshot) => (
                                        <Box whiteSpace="normal" {...provided.droppableProps}
                                            ref={provided.innerRef}>

                                            {siteList && listSites}
                                            {provided.placeholder}
                                        </Box>
                                    )
                                }

                            </Droppable>

                        </DragDropContext>





                    </CardContent>

                </Card>

            }
        </>

    )
}