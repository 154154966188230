

class SiteCache {

    constructor() {
        this.api = null;
        this.siteList = [];
        this.setList = [];
        
    }

    setApi(api) {
        this.api = api;
    }

    clear() {

        console.log('clear cache, lazy load');

        this.setList = [];

        this.siteList = [];
           

    }

    icon_override(siteset) {

        if (siteset === 'car') {
            return 'icon-3.png'
        } else if (siteset === 'car_cl') {
            return 'icon-6.png'
        } else if (siteset === 'cam') {
            return 'icon-2.png'
        } else if (siteset === 'cam_cs') {
            return 'icon-9.png'
        } else {
            return 'icon-7.png'
        }


    }


    async getSet(setname) {


        let icon = this.icon_override(setname);

        if (this.setList.includes(setname)) {
            console.log('in cache');
            return this.siteList.filter( (val) => {
                console.log(val);
                return val.category  === setname;
            });
        } else {
            console.log('not in cache');
            this.setList.push(setname);
            let a = await this.api.getPlaces(setname);
            a.forEach((ele) => {
                ele.icon = icon;
                this.siteList.push(ele);
            });
            
            return (a);
        }

    }
}

export default SiteCache;