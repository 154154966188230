import React from 'react';

import SvgIcon from '@material-ui/core/SvgIcon';

export default function GrassIcon(props) {
    return (
      <SvgIcon {...props}>

		<path  d="M13.179,18.096h3.577c-1.866-4.792,2.956-13.338,2.956-13.338
			C11.009,10.387,13.179,18.096,13.179,18.096z"/>
		<path d="M19.712,2.662c-7.162,2.58-8.796,6.235-8.796,6.235s1.656,3.447,1.196,4.909
			C14.02,6.092,19.712,2.662,19.712,2.662z"/>
		<path d="M0,4.288c0,0,4.824,8.546,2.956,13.338h3.578C6.534,17.627,8.704,9.918,0,4.288z"/>
		<path d="M3.387,1.616c0,0,5.887,10.432,3.608,16.281h4.367C11.362,17.896,14.01,8.486,3.387,1.616z"/>
	
      </SvgIcon>
    );
  }