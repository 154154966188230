import React from 'react';

import SvgIcon from '@mui/material/SvgIcon';

export default function CaravanIcon(props) {
    return (
	// eslint-disable-next-line 
      <SvgIcon viewBox="0 0 296.833 296.833" style="enable-background:new 0 0 24 24;" {...props}>

	<path d="M143,132.941c0-16.969,13.534-30.725,30.501-30.725c16.965,0,30.499,13.756,30.499,30.725V163.3h53.037
		c-5.614-30-14.526-71.99-17.613-83.646C235.742,65.742,223.286,55.3,208.24,55.3H32.495C14.738,55.3,0,69.461,0,87.216V163.3h143
		V132.941z M48,139.3v-37h66v37H48z"/>
	<path d="M81.075,210.097c-8.679,0-15.717,7.039-15.717,15.713c0,8.686,7.038,15.723,15.717,15.723
		c8.68,0,15.717-7.037,15.717-15.723C96.792,217.136,89.755,210.097,81.075,210.097z"/>
	<path d="M288.975,210.3h-31.688c1.075-2,1.998-3.81,2.708-5.818c1.185-3.35,1.83-5.445,1.83-10.447
		c-0.137-2.861-0.872-8.734-2.002-14.734H204v31h-61v-31H0v14.365C0,211.417,14.738,226.3,32.495,226.3H48.21
		c0-18,14.716-32.861,32.865-32.861s32.863,14.861,32.863,32.861H269v7.373c0,4.34,3.161,7.859,7.501,7.859
		c4.338,0,7.499-3.52,7.499-7.859V226.3h4.975c4.338,0,7.858-3.657,7.858-7.999C296.833,213.963,293.313,210.3,288.975,210.3z"/>	
      </SvgIcon>
    );
  }