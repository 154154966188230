import React, { useState, useEffect } from 'react';

import Typography from '@material-ui/core/Typography';

import Rating from '@material-ui/lab/Rating';
import { Grid } from '@material-ui/core';


import { makeStyles } from '@material-ui/core/styles';


const useStyles = makeStyles(theme => ({
    selects: {
        fontSize: 12,
        textAlign: 'left',
    },
    selectLabel: {
        textAlign: 'left',

    },
    title: {
        fontSize: 14,
    },
    siteDetails: {
        maxWidth: 500,
    },
    ratings: {
        fontSize: 12,
        fontWeight: "bolder",
        fontStyle: "oblique",
        justifyContent: 'center',
    },
}));


export default function SiteRatings(props) {

    const classes = useStyles();
    const site = props.site;
    const api = props.api;
    const [rating, setRating] = useState({});

    useEffect(() => {

        try {

            if (site.title !== 'My Location') {
                getRating(site._id);
            }

        } catch (e) {
            console.log(e);
        }
// eslint-disable-next-line 
    }, [site]);


    async function getRating(parentId) {
        let togo = await api.overallSiteRating(parentId);
        console.log(togo);
        setRating(togo);
    }



    function mainResult() {
        if (rating.hasOwnProperty('count') && rating.count > 0) {
            return (
                <Grid container direction="row"
                    justify="space-evenly"
                    alignItems="flex-start"
                >
                    <Grid><Typography className={classes.ratings}>Rated by: {rating.count}</Typography></Grid>
                    <Grid >
                        <Typography className={classes.ratings}>Overall Layout</Typography>
                        <Rating name="layoutRating" value={rating.layoutAvg} size="small" readOnly />
                    </Grid>


                    <Grid>
                        <Typography className={classes.ratings}>Overall Site Access</Typography>
                        <Rating name="accessRating" value={rating.accessAvg} size="small" readOnly />
                    </Grid>


                    <Grid>
                        <Typography className={classes.ratings}>Overall Pitch</Typography>
                        <Rating name="pitchRating" value={rating.pitchAvg} size="small" readOnly />
                    </Grid>
                </Grid>

            );

        } else {
            return(
                <Grid container></Grid>
            );
        }
    }



    return (
        <div>
            {site &&
                mainResult()
            }


        </div>

    );

}