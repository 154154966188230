import React, { useState } from 'react';

import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';

import RegisterToolbar from './registerToolbar'
import { TextField, Button } from '@material-ui/core';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';

import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import CardMedia from '@material-ui/core/CardMedia';

import FormControl from '@mui/material/FormControl';
import Input from '@mui/material/Input';
import InputLabel from '@mui/material/InputLabel';

import Alert from '@mui/material/Alert';

import { makeStyles } from '@material-ui/core/styles';


const useStyles = makeStyles((theme) => ({
    cards: {
        padding: theme.spacing(2),
        margin: 10,
        flexDirection: 'row',
        alignContent: 'center',
        alignItems: 'center',
        minWidth: 270,
    },
    root: {
        flexGrow: 1,

        marginTop: 80,
    },
    greenButton: {
        color: 'green',
    },
    blueButton: {
        color: 'blue',
    },
    typography: {
        padding: theme.spacing(2),
    },
    options: {
        fontSize: 12,
    },
    divider: {
        margin: 15,
    }
})
);

const signupSteps = [
    'Provides Basic Details',
    'Enter validation code from email',
    'Sign in!',
];


export default function SignUpIn(props) {

    const showme = props.showme;
    let api = props.api;

    const classes = useStyles();
    const [details, setDetails] = useState({
        name: '',
        password: '',
        email: '',
        code: ''
    });

    const [status, setStatus] = useState('initial');
    const [statusStep, setStatusStep] = useState(1);
    const [err, setErr] = useState(0);

    const [showPassword, setShowPassword] = React.useState(false);

    const handleClickShowPassword = () => setShowPassword((show) => !show);

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };



    function handleChange(event) {
        var a = { ...details, [event.target.id]: event.target.value };
        setDetails(a);

    };

    async function signIn() {

        setStatus("signin");
        setStatusStep(3);

        setErr(0);

        let res = await api.signin(details.name, details.password);

        if (res === -1) {
            setErr(1);
        } else {
            showme('sites');
        }

    }

    async function signUp() {

        setStatus("verify");
        setStatusStep(2);
        let res = await api.signUp(details.name, details.password, details.email);
        console.log(res);

    }

    async function verify() {


        setErr(0);

        try {
            let res = await api.verify(details.name, details.code);
            setStatus("registered");
            setStatusStep(3);
            console.log(res);

        } catch (e) {
            setErr(1);
        }


    }

    function menuPerform(val) {

        if (val === 0) {

        } else if (val === 1) {

        }

    }


    return (
        <>
            <RegisterToolbar
                showme={showme}
                perform={menuPerform}

            />

            {err === 1 &&
                <Alert severity="error">Please check and retry!</Alert>
            }

            <div className={classes.overall}>
                <Grid container spacing={1} className={classes.root}>
                    <Grid item xs={12}>
                        <Typography variant="h3">Signup and Sign in</Typography>
                    </Grid>

                </Grid>

                <p />

                <Box sx={{ width: '100%' }}>
                    <Stepper activeStep={statusStep} alternativeLabel>
                        {signupSteps.map((label) => (
                            <Step key={label}>
                                <StepLabel>{label}</StepLabel>
                            </Step>
                        ))}
                    </Stepper>
                </Box>

                <Grid container justify="space-evenly" alignItems="stretch" spacing={6} direction="row" className={classes.root}>
                    {statusStep === 1 &&
                        <Card variant="outlined" className={classes.cards}>
                            <CardMedia
                                className={classes.media}
                            />
                            <CardHeader title="Register as a new persion" subheader="Sign up to store your place breadcrumbs" />
                            <CardContent>

                                <TextField

                                    disabled={status !== 'initial'}
                                    label="Username"
                                    id="name"
                                    style={{ margin: 8 }}
                                    placeholder="User name"
                                    //                fullWidth
                                    margin="normal"
                                    required={true}
                                    value={details.name}
                                    onChange={handleChange}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                />

                                <TextField
                                    disabled={status !== 'initial'}
                                    label="email"
                                    id="email"
                                    style={{ margin: 8 }}
                                    placeholder="email address"
                                    helperText="Used to validate that you are a real person"
                                    //                fullWidth
                                    margin="normal"
                                    required={true}
                                    value={details.email}
                                    onChange={handleChange}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                />

                                <FormControl sx={{ m: 1, width: '25ch' }} variant="filled">
                                    <InputLabel htmlFor="filled-adornment-password">Password</InputLabel>
                                    <Input
                                        id="password"
                                        disabled={status !== 'initial'}
                                        type={showPassword ? 'text' : 'password'}
                                        endAdornment={
                                            <InputAdornment position="end">
                                                <IconButton
                                                    aria-label="toggle password visibility"
                                                    onClick={handleClickShowPassword}
                                                    onMouseDown={handleMouseDownPassword}
                                                    edge="end"
                                                >
                                                    {showPassword ? <VisibilityOff /> : <Visibility />}
                                                </IconButton>
                                            </InputAdornment>
                                        }
                                        value={details.password}
                                        onChange={handleChange}
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                    />
                                </FormControl>



                            </CardContent>



                            <CardActions>
                                <Button disabled={status !== 'initial'} variant="filled" className={classes.greenButton} fullWidth={true} onClick={() => { signUp() }}>Register as a place visitor!</Button>
                            </CardActions>

                        </Card>
                    }

                    {statusStep === 2 &&
                        <Card variant="outlined" className={classes.cards}>
                            <CardMedia
                                className={classes.media}
                            />
                            <CardHeader title="Verify Email" subheader="Enter the verification code" />
                            <CardContent>


                                <TextField
                                    disabled={status !== 'verify'}
                                    label="Verification code"
                                    id="code"
                                    style={{ margin: 8 }}
                                    placeholder="Verification code"
                                    helperText="Enter the verification code"
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    required={true}
                                    value={details.code}
                                    onChange={handleChange}
                                />

                            </CardContent>



                            <CardActions>
                                <Button disabled={status !== 'verify'} variant="filled" className={classes.greenButton} fullWidth={true} onClick={() => { verify() }}>Verify code</Button>
                            </CardActions>

                        </Card>

                    }

                    {statusStep === 3 &&
                        <Card variant="outlined" className={classes.cards}>
                            <CardMedia
                                className={classes.media}
                            />
                            <CardHeader title="Sign In" subheader="Sign in to see your breadcrumbs" />
                            <CardContent>

                                <TextField
                                    label="Username"
                                    id="name"
                                    helperText=" "
                                    style={{ margin: 8 }}
                                    placeholder="User name"
                                    //                fullWidth
                                    margin="normal"
                                    required={true}
                                    value={details.name}
                                    onChange={handleChange}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                />



                                <FormControl sx={{ m: 1, width: '25ch' }} variant="filled">
                                    <InputLabel htmlFor="filled-adornment-password">Password</InputLabel>
                                    <Input
                                        id="password"
                                        type={showPassword ? 'text' : 'password'}
                                        endAdornment={
                                            <InputAdornment position="end">
                                                <IconButton
                                                    aria-label="toggle password visibility"
                                                    onClick={handleClickShowPassword}
                                                    onMouseDown={handleMouseDownPassword}
                                                    edge="end"
                                                >
                                                    {showPassword ? <VisibilityOff /> : <Visibility />}
                                                </IconButton>
                                            </InputAdornment>
                                        }
                                        value={details.password}
                                        onChange={handleChange}
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                    />
                                </FormControl>

                            </CardContent>



                            <CardActions>
                                <Button variant="filled" className={classes.blueButton} fullWidth={true} onClick={() => { signIn() }}>Sign In</Button>
                            </CardActions>

                        </Card>

                    }


                </Grid>









            </div>



        </>
    );

}