import React, { useEffect, useMemo } from 'react';
import TextField from '@material-ui/core/TextField';

import Autocomplete from '@material-ui/lab/Autocomplete';
import throttle from 'lodash/throttle';



export default function SearchLocation(props) {

    const tell = props.tell;
    const loaded = props.loaded;
    const api = props.api;
    const sessionKey = props.sessionKey;
    const loggedIn = props.loggedIn;
    const fetchFreshKey = props.fetchFreshKey;

    const [inputValue, setInputValue] = React.useState('');
    const [options, setOptions] = React.useState([]);



    var slowdown = throttle(search, 200);

    useEffect(() => {
        console.log('In useEffect');
    }, [loaded]);

    useMemo(() => {
        console.log('In use Memo');
        slowdown()
    }, [ slowdown]);


    async function search() {

        if (inputValue.length > 2) {
            let a = await api.locationSearch(sessionKey, inputValue);

            if (a && a.hasOwnProperty("authenticationResultCode") && a.authenticationResultCode === 'CredentialsExpired') {
                     fetchFreshKey();
            }
            if (a && a.hasOwnProperty("resourceSets") && a.resourceSets[0].hasOwnProperty("resources")) {

                setOptions(a.resourceSets[0].resources);
            } 
        }

    }

    function returnResult(res) {
        if (res.name === '') {
            return;
        }
        let a =
        {
            location:
                { latitude: res.point.coordinates[0], longitude: res.point.coordinates[1] },
            bestView: [...res.bbox]
        };
        console.log(a);
        tell(a);
    }

    function testOptionSelected(option, value) {
        return (option.name === value);
    }



    return (

        <Autocomplete
            disabled = {! loggedIn }
            disableClearable
            label="Location Search"
            getOptionLabel={(option) => (typeof option === 'string' ? option : option.name)}
            filterOptions={(x) => x}
            options={options}
            onChange={(event, newValue) => {
                console.log('Onchange event:' + JSON.stringify(newValue));
                returnResult(newValue);
            }}
            onInputChange={(event, newInputValue) => {
                console.log('Onchange event:' + newInputValue);
                setInputValue(newInputValue);
            }}
            renderInput={(params) => (
                <TextField {...params} style={{ width: 200 }} size="small"  />
            )}
            getOptionSelected={testOptionSelected}
        />


    );
}