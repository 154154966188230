import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import Button from '@material-ui/core/Button';
import MenuItem from '@material-ui/core/MenuItem';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';

import MyLocationIcon from '@mui/icons-material/MyLocation';
import UserDrawer from './userdetails';
import Menu from '@material-ui/core/Menu';





const useStyles = makeStyles(theme => ({
    root: {
        flexGrow: 1,
    },
    menuButton: {
        marginRight: theme.spacing(2),
    },
    search: {
        backgroundColor: 'white',
    },
}));

export default function CampToolbar(props) {
    const classes = useStyles();
    const showme = props.showme;
    const api = props.api;

    const loggedIn = props.loggedIn;


    const perform = props.perform;


    const admin = props.admin;


    

    const [anchorEl, setAnchorEl] = React.useState(null);
    const [user, ] = useState(null);
    const [userDraw, ] = useState(false);





    useEffect(() => {

    }, []);


    const openMenu = event => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = (event, dest) => {
        setAnchorEl(null);
        if (dest === "-1") { return (showme('home')); }
        if (dest === "0") { return (perform(0)); }
        if (dest === "1") { return (perform(1)); }
        if (dest === "2") { return (showme('assessment')); }
        if (dest === "3") { return (showme('action')); }
    };




    function logout() {
        api.logout();
    }



    function login() {
        showme("signin");
    }

    return (
        <div className={classes.root}>
            <AppBar position="static">
                <Toolbar variant="dense">

                        <IconButton onClick={openMenu} edge="start" className={classes.menuButton} color="inherit" aria-label="menu">
                            <MenuIcon />
                        </IconButton>

                        <IconButton color="inherit"  onClick={(evt) => handleClose(evt, '0')}>
          <MyLocationIcon />
        </IconButton>

                        {/* <span className={classes.search}  > 
                            <SearchLocation 
                                loaded={loaded} 
                                tell={search} 
                                api={api}
                                sessionKey={sessionKey}
                                loggedIn={loggedIn}
                                fetchFreshKey={fetchFreshKey}
                                />
                        </span> */}
                        
                        
                    <Typography variant="h6" style={{ flex: 1 }} >

          </Typography>

          
                    <div>

                        {loggedIn &&
                           <Button variant="contained" size="small" color="primary" onClick={() => { logout() }}><AccountCircleIcon  /></Button> 
                        }
                        {!loggedIn &&
                            <Button variant="contained" size="small" color="primary" onClick={() => { login() }}> <AccountCircleIcon /> &nbsp; login</Button>
                        }
                    </div>

                </Toolbar>
            </AppBar>

            <Menu
                id="top-menu"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleClose}
            >
                <MenuItem value="home" onClick={(evt) => handleClose(evt, '-1')} divider={true}>Home</MenuItem>
                <MenuItem value="newsite" onClick={(evt) => handleClose(evt, '1')} divider={true} disabled={!loggedIn}>New Place</MenuItem>

                
            </Menu>

            {userDraw &&
                <UserDrawer user={user} admin={admin} close={console.log('closeUser')} />}

        </div>
    );
}